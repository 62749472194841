import React, { memo, useEffect, useRef } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, Card, Checkbox, FormGroup, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';

import { book, Loader } from '@monorepo/common';

import { IRenewStep2Response, IStep1Response } from '../../../../services/serviceMagazine/interfaces';
import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { usePutRenewDataMutation } from '../../../../services/serviceMagazine';
import { displayTypePage } from '../../../../features/sliceTemporaryData';
import { showNotification } from '../../../../features/sliceNotification';
import { IEventsStepProps } from '../../interfaceSteps';
import { SETTINGS } from '../../../../constants/constants';

import { useStyles } from '../../styles';
import AddressInfo from '../../../../components/AddressInfo/AddressInfo';

interface IStep2Props extends IEventsStepProps{
  step3: boolean,
  step1Data?: IStep1Response | null,
  step2data: IRenewStep2Response | null,
  setStep3: (val: boolean) => void,
  Nav: React.ElementType
}

const Step3 = ({ onBack, step3, setStep3, step1Data, step2data, Nav }:IStep2Props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { slug } = useParams<{ slug: string }>();
  const [putRenewData, { data, isLoading, error }] = usePutRenewDataMutation();

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const saveData = async () => {
    if (recaptchaRef && recaptchaRef.current) {
      const captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      if (captchaToken) {
        if (step1Data && step2data) {
          await putRenewData({
            ...step2data,
            country: step2data.country === 'US' ? 'USA' : step2data.country,
            captchaToken,
            ...(step1Data) && { subscriptionType: step1Data.value }
          });
        }
        return;
      }
      dispatch(showNotification({
        show: true,
        type: 'error',
        text: 'Captcha token not found'
      }));
    }
  };
  useEffect(() => {
    if (data && !slug) {
      dispatch(displayTypePage('renew'));
      history.push(book.magazine.subscriptionComplete);
    }
    if (data && slug) {
      dispatch(showNotification({
        show: true,
        type: 'success',
        text: 'Data saved!'
      }));
      const timeout = setTimeout(() => {
        window.location.href = `https://issuu.com/bevnet/docs/${slug}`;
        clearTimeout(timeout);
      }, 1000);
    }

    if (error && ('data' in error)) {
      const { message } = error.data as { message: string };
      dispatch(showNotification({
        show: true,
        type: 'error',
        text: message
      }));
    }
  }, [data, history, error, slug]);

  return (
    <Card className={classes.wrapper_card}>
      <div data-cy="final_step">
        <Loader isShow={isLoading} />
        <ReCAPTCHA
          style={{
            visibility: 'hidden'
          }}
          ref={recaptchaRef}
          size="invisible"
          sitekey={SETTINGS.RECAPTCHA_PUBLIC_KEY}
        />
        <Typography
          sx={{ mb: 3 }}
          variant="body2"
        >
          You have selected a
          {' '}
          <b data-cy="subscriptionTypeValue">{step1Data?.title}</b>
          {' '}
          subscription to be sent to:
        </Typography>

        {step2data && (
          <AddressInfo
            data={step2data}
            type={step1Data || null}
          />
        )}

        <FormGroup className={classes.step3_checkbox_line}>
          <FormControlLabel
            checked={step3}
            onChange={(a, b:boolean) => {
              setStep3(b);
            }}
            control={<Checkbox />}
            label={(
              <Typography variant="body2">
                Yes, I would like to receive BevNET Magazine
              </Typography>
          )}
          />
        </FormGroup>

        <Typography
          sx={{ mb: 3 }}
          variant="body2"
          fontWeight="bold"
        >
          Press continue to finalize your subscription.
        </Typography>

        <Nav
          nextDisabled={false}
          onBack={onBack}
          nextButton={(
            <Button
              type="button"
              disabled={!step3}
              onClick={saveData}
              variant="contained"
              endIcon={(<ArrowRightIcon />)}
            >
              Continue
            </Button>
            )}
        />
      </div>
    </Card>
  );
};
export default memo(Step3);
