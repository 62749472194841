import React from 'react';
import { Typography } from '@mui/material';

import { ErrorBoundary } from '@monorepo/common';

import { IRenewStep2Response, IStep1Response } from '../../services/serviceMagazine/interfaces';

interface IAddressInfoProps {
  data: IRenewStep2Response,
  type: IStep1Response | null
}

const AddressInfo = ({ data, type }:IAddressInfoProps) : JSX.Element => {
  const { name, email, company, address1, province, state, city, zipCode, country, title, phone } = data;

  return (
    <ErrorBoundary>
      <Typography
        variant="body1"
        lineHeight={2}
        fontWeight="bold"
      >
        Subscriber:
      </Typography>

      <Typography
        sx={{ mb: 3 }}
        variant="body1"
        lineHeight={2}
      >
        {name}
        <br />
        {title}
        <br />
        {company}
        <br />
        {address1}
        {' '}
        <br />
        {city}
        {', '}
        {state || province}
        {' '}
        {zipCode}
        <br />
        {country && country}
        <br />
        {email}
        <br />
        {phone}
        <br />
        {type && `Format: ${type.title}`}
      </Typography>
    </ErrorBoundary>
  );
};

export default AddressInfo;
