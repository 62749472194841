import React, { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { Button, Card } from '@mui/material';

import {
  Loader,
  FormField,
  useHookForm,
  useIsProvince,
  FormAutocomplete,
  GlobalInterfaces,
  CountryAutocomplete,
  UsaStatesAutocomplete,
  useCustomGoogleAutocomplete,
} from '@monorepo/common';

import { useGetPrimaryBusinessTypesQuery, useGetRolesInCompanyQuery } from '../../../../services/serviceMagazine';
import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { IRenewStep2Response, IStep2SelectOptionsResponse } from '../../../../services/serviceMagazine/interfaces';
import { IEventsStepProps } from '../../interfaceSteps';
import { schema } from './schema';

import { useStyles } from '../../styles';

interface IStep2Props extends IEventsStepProps{
  setStep2: (a: IRenewStep2Response) => void,
  step2: IRenewStep2Response | null,
  Nav: React.ElementType
}

const Step2 = ({ onBack, onNext, setStep2, step2, Nav }:IStep2Props) => {
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState<string | null>('US');
  const [isLoading] = useState<boolean>(false);
  const { data: rolesOptions, isLoading: loadRolesOptions } = useGetRolesInCompanyQuery(null);
  const { data: businessOptions, isLoading: LoadBusinessOptions } = useGetPrimaryBusinessTypesQuery(null);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    errors
  } = useHookForm<IRenewStep2Response>({
    schema,
    ifErrorScrollPageToUp: true,
    defaultValues: step2 || null
  });

  const { getData, isProvince } = useIsProvince({
    extraOptions: {
      setValue,
      stateFieldName: 'state',
      provinceFieldName: 'province'
    },
    defaultProvince: !((step2?.country === 'US' || step2?.country === 'USA'))
  });

  const { changeInput, renderedList, clearSuggestions } = useCustomGoogleAutocomplete({
    country: countryCode || '',
    mode: 'custom',
    setValue,
    autoFillFields: {
      state: 'state',
      province: 'province',
      address1: 'address1',
      city: 'city',
      zip: 'zipCode'
    }
  });

  const onSubmit = useCallback((data: IRenewStep2Response) => {
    if (data && onNext) {
      setStep2(data);
      onNext();
    }
  }, [countryCode, onNext]);

  // if choose country (non-null assertion operator removed)
  const changeCountries = useCallback((val:GlobalInterfaces.IAutocompleteOption | null) => {
    clearSuggestions();
    getData({
      selectOption: val
    });
    setCountryCode(val?.alpha2 || null);
  }, [getData]);

  // toggle isProvince
  useEffect(() => {
    setValue('isShowState', !isProvince);
  }, [isProvince]);

  return (
    <Card className={classes.wrapper_card}>
      <Loader
        isShow={isLoading}
      />

      <form
        data-cy="step2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CountryAutocomplete
          isDisabled={(step2 && (step2.country === 'USA' || step2.country === 'US')) || false}
          isRequired={(step2 && (step2.country !== 'USA' && step2.country !== 'US')) || false}
          errors={errors}
          disableClearable
          setValue={setValue}
          label="Country"
          getValues={getValues}
          handleChange={changeCountries}
          extraProps={{ ...register('country') }}
        />
        <FormAutocomplete<IStep2SelectOptionsResponse>
          optionsSettings={{
            optionText: 'text',
            optionValue: 'value'
          }}
          isRequired
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          label="Primary Business"
          placeholder="Select value"
          options={businessOptions || []}
          isLoading={LoadBusinessOptions}
          extraProps={{ ...register('primaryBusiness') }}
        />
        <FormField
          isRequired
          errors={errors}
          label="Full Name"
          placeholder="Name"
          extraProps={{ ...register('name') }}
        />
        <FormField
          isRequired
          errors={errors}
          label="Job Title"
          placeholder="Job Title"
          extraProps={{ ...register('title') }}
        />
        <FormAutocomplete<IStep2SelectOptionsResponse>
          optionsSettings={{
            optionValue: 'value',
            optionText: 'text'
          }}
          isRequired
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          options={rolesOptions || []}
          isLoading={loadRolesOptions}
          placeholder="Select value"
          label="Which best describes your role?"
          extraProps={{ ...register('roleInCompany') }}
        />
        <FormField
          isRequired
          label="company"
          errors={errors}
          placeholder="Company"
          extraProps={{ ...register('company') }}
        />
        <FormField
          isRequired
          label="email"
          errors={errors}
          placeholder="Email"
          extraProps={{ ...register('email') }}
        />
        <FormField
          type="number"
          isRequired
          label="phone"
          errors={errors}
          placeholder="Phone #"
          extraProps={{ ...register('phone') }}
        />
        <FormField
          type="number"
          label="fax"
          errors={errors}
          placeholder="Fax"
          extraProps={{ ...register('fax') }}
        />

        <FormField
          isRequired
          errors={errors}
          label="Address"
          placeholder="Address"
          extraProps={{ ...register('address1') }}
          eventInput={(inputValue, event) => {
            changeInput(event as ChangeEvent<HTMLInputElement>);
          }}
        >
          {renderedList}
        </FormField>
        <FormField
          errors={errors}
          label="address2"
          extraProps={{ ...register('address2') }}
          placeholder="Apartment, suite, unit, building, floor, etc."
        />

        <div className={classes.step3_address_wrap}>
          <FormField
            isRequired
            label="City"
            errors={errors}
            placeholder="City"
            extraProps={{ ...register('city') }}
          />
          <FormField
            errors={errors}
            label="Province"
            visible={isProvince}
            placeholder="Province"
            extraProps={{ ...register('province') }}
          />
          <UsaStatesAutocomplete
            label="State"
            isRequired
            disableClearable
            errors={errors}
            visible={!isProvince}
            getValues={getValues}
            setValue={setValue}
            extraProps={{ ...register('state') }}
          />
        </div>

        <FormField
          isRequired
          type="number"
          errors={errors}
          label="ZIP/Postal"
          extraProps={{ ...register('zipCode') }}
        />

        <Nav
          nextDisabled={false}
          onBack={onBack}
          nextButton={(
            <Button
              type="submit"
              variant="contained"
              endIcon={(<ArrowRightIcon />)}
            >
              Next Step
            </Button>
          )}
        />
      </form>

    </Card>
  );
};

export default memo(Step2);
