import { GlobalInterfaces } from '@monorepo/common';

interface IDateConverter {
  type: 'month' | 'years',
  setData: (data:GlobalInterfaces.IOption[]) => void
}
export const dateConverter = ({ type, setData }:IDateConverter) : void => {
  if (type === 'month') {
    const monthArray:GlobalInterfaces.IOption[] = [];
    for (let i = 1; i <= 12; i++) {
      monthArray.push({
        value: i.toString(),
        text: i.toString()
      });
    }
    setData(monthArray);
  }
  if (type === 'years') {
    const yarArray = [];
    const d = new Date();
    const currentYear: number = d.getFullYear();
    for (let i:number = currentYear; i < currentYear + 10; i++) {
      yarArray.push({
        value: i.toString(),
        text: i.toString()
      });
    }
    setData(yarArray);
  }
};
