import React, { memo } from 'react';
import {
  Card
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import clsx from 'clsx';

import { Loader, PlugNoData } from '@monorepo/common';

import Step1Label from './Step1Label';
import { IEventsStepProps } from '../../interfaceSteps';
import { IStep1Response } from '../../../../services/serviceMagazine/interfaces';
import { useGetSubscriptionTypesQuery } from '../../../../services/serviceMagazine';

import { useStyles } from '../../styles';

interface IStep1Props extends IEventsStepProps{
  Nav: React.ElementType;
  country: string | 'USA' | 'US';
  defaultData: IStep1Response | null;
  setStep1: (a:IStep1Response | null) => void;
}
const Step1 = ({ onNext, setStep1, defaultData, Nav, country }:IStep1Props) => {
  const classes = useStyles();
  const { isLoading, data } = useGetSubscriptionTypesQuery(null);

  return (
    <div data-cy="step1">
      <Loader isShow={isLoading} />
      <Card>
        <FormControl
          className={classes.step_1_wrapper}
        >
          {!data && defaultData && (
            <PlugNoData />
          )}

          <RadioGroup
            onChange={(b, val) => {
              const result:IStep1Response | undefined = data?.find((item:IStep1Response) => item.value.toString() === val);
              if (country === 'USA' || country === 'US' || country === 'UNITED STATES') setStep1(result || null);
            }}
          >
            {data?.map((item:IStep1Response) => (
              <FormControlLabel
                data-cy={`cy-item-${item.value.toString()}`}
                key={item.value}
                className={clsx(classes.step_1_item, {
                  [classes.none_hover]: country !== 'USA' && country !== 'US',
                  [classes.active]: defaultData && (defaultData.value.toString() === item.value.toString()),
                  checked_item: !!defaultData && (defaultData.value.toString() === item.value.toString())
                })}
                value={item.value.toString()}
                control={<Radio checked={!!defaultData && (defaultData.value.toString() === item.value.toString())} />}
                label={(
                  <Step1Label
                    title={item.title}
                    subtitle={item.description}
                  />
                      )}
              />
            ))}

          </RadioGroup>
        </FormControl>
      </Card>

      <Nav
        onNext={onNext}
        nextDisabled={!defaultData}
      />
    </div>
  );
};
export default memo(Step1);
