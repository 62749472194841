import React from 'react';
import { Typography, Button } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { book } from '@monorepo/common';
import { useDispatch } from 'react-redux';
import { useStyles } from '../styles';
import { toggleSkipStartPage } from '../../../features/sliceTemporaryData';

interface ICancelInternalProps {
  title?: string,
  cancel: () => void
}
const CancelContent = ({ cancel, title }:ICancelInternalProps) : JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Typography
        sx={{ mb: 3 }}
        variant="h4"
        fontWeight="bold"
      >
        Are you sure that you want to cancel your FREE subscription to BevNET Magazine?
      </Typography>

      <Typography
        variant="h6"
        fontWeight="bold"
      >
        Your subscription details:
      </Typography>
      <Typography
        variant="body2"
      >
        {title && title}
      </Typography>

      <Button
        onClick={cancel}
        variant="contained"
        className={classes.btn}
        data-cy="cancel_renew_button"
      >
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Yes, I'd like to cancel.
      </Button>

      <Button
        data-cy="continue_subscriptions_button"
        variant="outlined"
        className={classes.btn2}
        onClick={() => id && history.push(book.magazine.renew(id))}
      >
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        No, I'd like to continue my free subscription
      </Button>

      <Typography
        variant="h6"
        fontWeight="bold"
      >
        Did you know that you can convert your print subscription to a digital edition?
      </Typography>

      <Typography
        variant="body2"
      >
        Change your subscription preferences on the renewal form.
      </Typography>

      <Typography
        data-cy="back_to_renew-page"
        style={{ marginTop: 20, color: '#D14343', textDecoration: 'underline', cursor: 'pointer' }}
        variant="body2"
        onClick={() => {
          dispatch(toggleSkipStartPage(true));
          history.push(book.magazine.renew(id));
        }}
      >
        Edit subscription details
      </Typography>
    </>
  );
};

export default CancelContent;
