import { formattedUrl } from '../utils/formattedUrl';

interface IRegDesk {
  selectEvent: () => string,
  selectPrinter: (eventId?: string) => string,
  eventHome: (eventId?: string) => string,
  manualCheckIn: (eventId?: string) => string,
  attendeeDetails: (eventId?: string, attendeeId?: string) => string,
  qrCheckIn: (eventId?: string) => string,
  displaySuccessMessage: (eventId?: string, attendeeId?: string) => string;
}
interface IAccount {
  user: string,
  userAccount: string,
  userUpdate: string,
  userPublicProfile: string,
  userOrders: string,
  userChangePassword: string;
  confirmEmail: string;
}

interface IBook {
  readonly home: string,
  readonly account: IAccount,
  readonly admin: {
    account: {
      login: string,
    }
    eventRegDesk: IRegDesk,
    content: {
      linkSharing: string,
      linkSharingDetails: (id?: string) => string;
      linkShareLogView: (linkSharingItemId?: string, id?: string) => string;
      linkSharingCreate: string,
    },
    grids: {
      grids: string,
      searchAll: string,
      createGridItem: (id?: string | null) => string,
      gridsDetails: (id?: string) => string;
      editGridsDetails: (id?: string,) => string;
    },
    jobs: {
      jobs: string,
      jobDetails: (id?: string) => string,
      prepaid: string,
      prepaidDetails: (id?: string) => string,
      categories: string,
      reports: string,
      orderDetail: (orderId: string) => string,
      chargifySubDetails: (chargifySubId: string) => string
    },
    brands: {
      uploadReviewPhoto: string
    },
    newsletters: {
      scheduler: string,
      editions: string,
      libraryItems: string,
      updateLibraryItem: (id?: string) => string,
      createLibraryItem: string,
      updateEditions: (id?: string) => string,
      editionItem: (editionId?: string, itemId?: string | null) => string,
      editionItemDetail: (editionId?: string, itemId?: string | null) => string,
      editionDetail: (id?: string) => string,
    }
  },
  readonly magazine: {
    renew: (id?: string, slug?: string) => string;
    subscribe: string,
    subscriptionComplete: string,
    cancel: (id?: string) => string,
    issue: (slug?:string, id?: string) => string
  },
  readonly register: {
    eventRegister: string
  },
  readonly insider: {
    registerCommunityCall: string
  }
}

export const book: IBook = {
  home: '/',
  account: {
    user: '/user',
    userAccount: '/user/account',
    userUpdate: '/user/update',
    userPublicProfile: '/user/publicprofile',
    userOrders: '/user/orders',
    userChangePassword: '/user/changepassword',
    confirmEmail: '/user/confirm-email',
  },
  //
  admin: {
    account: {
      login: '/login',
    },
    eventRegDesk: {
      selectEvent: () => ('/events/reg-desk/select-event'),
      selectPrinter: (eventId) => (eventId
        ? `/events/${eventId}/reg-desk/select-printer`
        : '/events/:eventId/reg-desk/select-printer'),

      eventHome: (eventId) => (eventId ? `/events/${eventId}/reg-desk` : '/events/:eventId/reg-desk'),

      manualCheckIn: (eventId) => (eventId
        ? `/events/${eventId}/reg-desk/manual-check-in`
        : '/events/:eventId/reg-desk/manual-check-in'),

      attendeeDetails: (eventId, attendeeId) => (eventId
        ? `/events/${eventId}/manual-check-in/attendee-details/${attendeeId}`
        : '/events/:eventId/manual-check-in/attendee-details/:attendeeId'),

      qrCheckIn: (eventId) => (eventId ? `/events/${eventId}/reg-desk/qr-check-in` : '/events/:eventId/reg-desk/qr-check-in'),

      displaySuccessMessage: (eventId, attendeeId) => (eventId
        ? `/manual-check-in/${eventId}/display-success-message/${attendeeId}`
        : '/manual-check-in/:eventId/display-success-message/:attendeeId')
    },
    content: {
      linkSharing: '/content/LinkSharing',
      linkSharingDetails: (id) => (id
        ? `/content/LinkSharing/details/${id}`
        : '/content/LinkSharing/details'),

      linkShareLogView: (linkSharingItemId, id) => (id
        ? `/content/Linksharing/${linkSharingItemId}/logviews/${id}`
        : '/content/Linksharing/:linkSharingItemId/logviews/:id'),
      linkSharingCreate: '/content/LinkSharing/Create'
    },
    grids: {
      grids: '/Magazine/Grids',
      gridsDetails: (id) => (id
        ? `/Magazine/Grids/Detail/${id}`
        : '/Magazine/Grids/Detail/:id'),
      searchAll: '/Magazine/Grids/SearchAll',

      createGridItem: (id) => {
        if (id && id !== null) {
          return `/Magazine/Grids/CreateGridItem/${id}`;
        }
        if (id === null) {
          return '/Magazine/Grids/CreateGridItem';
        }
        return '/Magazine/Grids/CreateGridItem/:id?';
      },

      editGridsDetails: (id) => (id
        ? `/Magazine/Grids/EditGridItem/${id}`
        : '/Magazine/Grids/EditGridItem/:id'),
    },
    jobs: {
      jobs: '/jobs',
      jobDetails: (id) => (id ? `/jobs/details/${id}` : '/jobs/details'),
      prepaid: '/jobs/prepaid',
      prepaidDetails: (id) => (id ? `/jobs/prepaidDetails/${id}` : '/jobs/prepaidDetails'),
      categories: '/jobs/categories',
      reports: '/jobs/reports',
      orderDetail: (orderId) => (`${formattedUrl('https://admin.bevnet.com', 'http://localhost:3154')}/orders/orderdetails/${orderId}`),
      chargifySubDetails: (chargifySubId) => (`https://bevnet.chargify.com/subscriptions/${chargifySubId}`)
    },
    brands: {
      uploadReviewPhoto: '/brands/:siteId?/reviews/:reviewId?/photo'
    },
    newsletters: {
      scheduler: '/Newsletter',
      editions: '/Newsletter/Editions',
      libraryItems: '/Newsletter/LibraryItems',
      updateLibraryItem: (id) => (id
        ? `/Newsletter/LibraryItems/Edit/${id}`
        : '/Newsletter/LibraryItems/Edit/:id'
      ),
      createLibraryItem: '/Newsletter/LibraryItems/Create',
      updateEditions: (id) => (id
        ? `/Newsletter/Editions/Edit/${id}`
        : '/Newsletter/Editions/Edit/:id'),
      editionItem: (editionId, itemId?) => {
        if (editionId && itemId) return `/Newsletter/Editions/${editionId}/Item/${itemId}`;
        if (editionId) return `/Newsletter/Editions/${editionId}/Item`;
        return '/Newsletter/Editions/:editionId/Item/:itemId?';
      },
      editionItemDetail: (editionId, itemId) => (editionId && itemId
        ? `/Newsletter/Editions/Detail/${editionId}/Item/${itemId}`
        : '/Newsletter/Editions/Detail/:editionId/Item/:itemId?'),
      editionDetail: (id) => (id
        ? `/Newsletter/Editions/Detail/${id}`
        : '/Newsletter/Editions/Detail/:id'),
    },
  },
  magazine: {
    renew: (id, slug) => {
      if (!slug) {
        if (id) {
          return `/renew/${id}`;
        }
        return '/renew/:id?/:issue?/:slug?';
      }
      return `/renew/${id}/issue/${slug}`;
    },
    cancel: (id) => (id ? `/cancel/${id}` : '/cancel/:id'),
    issue: (slug?: string, id?: string) => (slug && id ? `/issue/${slug}/${id}` : '/issue/:slug?/:id?'),
    subscribe: '/subscribe',
    subscriptionComplete: '/subscriptioncomplete',
  },
  register: {
    eventRegister: '/Event/Register/:eventslug'
  },
  insider: {
    registerCommunityCall: '/community-call/:meetingGuid'
  }
};
