import React, { memo } from 'react';
import { Link } from 'react-router-dom';

interface INotificationLinkToRenewProps {
  link: string
}
export const NotificationLinkToRenew = memo(({ link }:INotificationLinkToRenewProps) => (
  <>
    <b> You already have a subscription to BevNET Magazine</b>
    <br />
    <Link to={link}>Edit/Renew your subscription</Link>
  </>
));
