import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cookies from 'js-cookie';

import { book, cookieNames, useStepper } from '@monorepo/common';

import Step1 from './steps/Steps1';
import Step2 from './steps/Steps2';
import Step3 from './steps/Step3';
import FinalStep from './steps/FinalStep';

import {
  useGetSubscriptionTypesQuery,
  useLazyGetStepsTempDataQuery,
  useLazyGetUserInfoQuery,
  useSaveStepsTempDataMutation
} from '../../services/serviceMagazine';
import { NotificationLinkToRenew } from './components/NotificationLinkToRenew';
import { IStep1Response } from '../../services/serviceMagazine/interfaces';
import { staticValueForStep2, staticValueForStep3 } from './staticValues';
import { showNotification } from '../../features/sliceNotification';
import { IStep2data, IStep3Payment } from './stepsInterfaces';
import { Step1Values } from '../../constants/constants';
import InfoPanel from './components/InfoPanel';
import { showDropButton } from '../../features/sliceStepper';
import { useAppSelector } from '../../store/hooks';

export const Subscribe = () => {
  const dispatch = useDispatch();
  const { isStepperDrop } = useAppSelector(({ stepperOptions }) => ({
    isStepperDrop: stepperOptions.isDrop
  }));
  const [getUserInfo, { data: userProfile, isLoading: isLoadUserProfile, isSuccess: isLoadedUserProfile, isFetching }] = useLazyGetUserInfoQuery();
  const [fetchStepsTempData, { data: getSavedTempData, isLoading: getTempDataLoading }] = useLazyGetStepsTempDataQuery();

  const [isTemporaryDisableRequest, setIsTemporaryDisableRequest] = useState<boolean>(true);
  // show/hide payment step
  const [showStepPayment, setShowStepPayment] = useState<boolean>(false);
  const [isFirstLoadPage, setIsFirstLoadPage] = useState(true);

  const [saveStepsData, { data: savedData, error: errSavedData }] = useSaveStepsTempDataMutation();
  const { isLoading: loadingStep1, data: step1Data } = useGetSubscriptionTypesQuery(null, {
    skip: !isLoadedUserProfile
  });

  const [step1, setStep1] = useState<IStep1Response | null>(null);
  const [step2, setStep2] = useState<IStep2data>(staticValueForStep2);
  const [step3, setStep3] = useState<IStep3Payment>(staticValueForStep3);
  const [finalStep, setFinalStep] = useState<boolean>(false);

  useEffect(() => {
    getUserInfo();
  }, []);

  // init stepper
  const { putSteps, onNext, onBack, onComplete, renderStepper, StepsButtons, putStepperRightToolbar, dropStepper } = useStepper({
    leftInfoPanel: (<InfoPanel />),
    leftInfoPanelPhonePosition: 'top',
    helmet: 'BevNET Magazine: Subscription Renewal',
    isLoading: getTempDataLoading || loadingStep1 || isLoadUserProfile || isFetching
  });
  // if user AUTH get user data
  useEffect(() => {
    if (userProfile?.isLoggedIn && userProfile?.isSubscribed && userProfile?.subscriptionDetails) {
      putStepperRightToolbar({
        type: 'info',
        body: <NotificationLinkToRenew link={book.magazine.renew(userProfile.subscriptionDetails.guid)} />
      });
    }
    if (userProfile?.isLoggedIn && !userProfile?.isSubscribed && userProfile?.userDetails) {
      // first load page. Fill user data
      if (!cookies.get(cookieNames.tempDataId)) {
        setIsTemporaryDisableRequest(true);
        setStep2({
          ...staticValueForStep2,
          ...userProfile.userDetails,
          ...(userProfile.userDetails.country && (userProfile.userDetails.country === 'United States' || userProfile.userDetails.country === 'USA')) && {
            country: 'US'
          }
        });
      }

      dispatch(showNotification({
        show: true,
        type: 'warning',
        text: "You don't have a subscription, please fill out the form"
      }));
    }
  }, [userProfile, isStepperDrop, isFetching]);

  useEffect(() => {
    if (isFirstLoadPage && !cookies.get(cookieNames.tempDataId)) {
      setIsFirstLoadPage(false);
    }
    if (isFirstLoadPage && cookies.get(cookieNames.tempDataId)) {
      fetchStepsTempData(cookies.get(cookieNames.tempDataId) as string);
    }
  }, [isLoadedUserProfile, isFetching]);

  // show-hide payment step and clear data in step3
  useEffect(() => {
    // setShowStepPayment(step1?.value !== Step1Values.DIGITAL_ONLY && step2?.country !== 'US');
    setStep3(step1?.value === Step1Values.DIGITAL_ONLY ? staticValueForStep3 : step3);
  }, [step1, step2, step3]);

  // for update data into steps
  useEffect(() => {
    dispatch(showDropButton());
    putSteps([
      {
        label: 'Subscription Type',
        content: (
          <Step1
            disabledNext={isFetching}
            data={step1Data}
            country="US"
            Nav={StepsButtons}
            onNext={onNext}
            activeId={step1}
            setStep={setStep1}
            setIsTemporaryDisableRequest={setIsTemporaryDisableRequest}
          />
        )
      },
      {
        label: 'Subscription Details',
        content: (
          <Step2
            step1Value={step1?.value || null}
            setShowStepPayment={setShowStepPayment}
            Nav={StepsButtons}
            onBack={onBack}
            onNext={onNext}
            step2={step2}
            setStep2={setStep2}
          />
        )
      },
      ...(showStepPayment ? [{
        label: 'Payment Details',
        content: (
          <Step3
            Nav={StepsButtons}
            onBack={onBack}
            onNext={onNext}
            step3={step3}
            setStep3={setStep3}
          />
        )
      }] : []),
      {
        label: 'Confirmation',
        content: (
          <FinalStep
            step3={step3}
            Nav={StepsButtons}
            finalStep={finalStep}
            step2data={step2}
            step1Data={step1}
            setFinalStep={setFinalStep}
            onBack={onBack}
            onNext={onComplete}
          />
        )
      }
    ]);
  }, [step1, step2, finalStep, step3, showStepPayment, step1Data, isFetching]);

  // save temporary data
  useEffect(() => {
    if (!isTemporaryDisableRequest) {
      saveStepsData({
        step1: {
          subscriptionType: step1?.value || Step1Values.DIGITAL_ONLY
        },
        step2,
        step3
      });
    }
  }, [step1, step2, finalStep, step3, isTemporaryDisableRequest]);

  useEffect(() => {
    if (savedData?.guid && !cookies.get(cookieNames.tempDataId)) {
      cookies.set(cookieNames.tempDataId, savedData.guid);
      dispatch(showDropButton());
    }
  }, [savedData, errSavedData]);

  // Get data from server and fill local steps
  useEffect(() => {
    if (getSavedTempData) {
      setIsTemporaryDisableRequest(true);
      if (step1Data) {
        const res:IStep1Response | undefined = step1Data.find((item) => item.value === getSavedTempData.step1.subscriptionType);
        if (res) setStep1(res);
      }
      // fill other steps
      if (isFirstLoadPage) {
        setStep2(getSavedTempData.step2);
        setStep3({
          ...staticValueForStep3,
          ...getSavedTempData.step3
        });

        setIsFirstLoadPage(false);
      }
    }

    // fill step1
    dispatch(showDropButton());
  }, [getSavedTempData, step1Data, showDropButton]);

  useEffect(() => {
    if (isStepperDrop) {
      setIsTemporaryDisableRequest(true);
      setStep1(null);
      setStep2(staticValueForStep2);
      setStep3(staticValueForStep3);
      dispatch(showNotification({
        show: true,
        type: 'info',
        text: 'Form cleared'
      }));
      dropStepper();
      getUserInfo();
    }
  }, [isStepperDrop]);

  return (
    <div data-cy="subscribe_stepper">
      {renderStepper}
    </div>
  );
};
