import { Typography } from '@mui/material';

import { ErrorBoundary } from '@monorepo/common';

import { IRenewStep2Response, IStep1Response } from '../../../services/serviceMagazine/interfaces';

interface IAddressInfoProps {
  data: IRenewStep2Response,
  type: IStep1Response | null
}

const AddressInfo = ({ data, type }:IAddressInfoProps) => {
  const { name, email, company, address1, province, state, city, zipCode, country, title, phone } = data;

  return (
    <ErrorBoundary>
      <Typography
        variant="body1"
        lineHeight={2}
        fontWeight="bold"
      >
        Subscriber:
      </Typography>

      <Typography
        sx={{ mb: 3 }}
        variant="body1"
        lineHeight={2}
      >
        <span data-cy="name">{name}</span>
        <br />
        <span data-cy="title">{title}</span>
        <br />
        <span data-cy="company">{company}</span>
        <br />
        <span data-cy="address1">{address1}</span>
        {' '}
        <br />
        {city}
        {', '}
        {state || province}
        {' '}
        {zipCode}
        <br />
        {country && country}
        <br />
        <span data-cy="email">{email}</span>
        <br />
        <span data-cy="phone">{phone}</span>
        <br />
        {type && `Format: ${type.title}`}
      </Typography>
    </ErrorBoundary>
  );
};

export default AddressInfo;
