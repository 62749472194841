export type TypePage = 'renew' | 'subscription' | null;

export interface ITemporaryDataReducer {
  skipStartPage?: boolean,
  typePage: TypePage
}
export const temporaryDataInitialState:ITemporaryDataReducer = {
  skipStartPage: false,
  typePage: null
};
