import React, { memo } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { UserMenu } from '@monorepo/common';
import BurgerMenu from '../BurgerMenu';
import IconLogo from '../Icons/IconLogo';

import { useStyles } from './styles';

interface HeaderProps {
  isPhoneButton?: boolean
}
const Header = ({ isPhoneButton = false }: HeaderProps) => {
  const classes = useStyles();

  return (
    <AppBar
      position="sticky"
      className={classes.header}
    >
      <Toolbar>
        {isPhoneButton && <BurgerMenu />}
        <IconLogo className={classes.logo} />
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};

export default memo(Header);
