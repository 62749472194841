import React, { memo, useEffect, useState } from 'react';
import { Portal, Snackbar } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import clsx from 'clsx';
import { closeNotification } from '../../features/sliceNotification';

import { useStyles } from './styles';
import { useAppSelector } from '../../store/hooks';

const Alert = (props: AlertProps) => (
  <MuiAlert
    elevation={6}
    variant="filled"
    {...props}
  />
);

// interface NotificationProps {}
const Notification = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { text, type, show, iconClose } = useAppSelector(({ notification }) => ({
    type: notification.type,
    text: notification.text,
    show: notification.show,
    iconClose: notification.iconClose
  }));
  const handleClose = () => {
    setOpen(false);
    dispatch(closeNotification());
  };

  useEffect(() => {
    if (!iconClose) {
      const timeout = setTimeout(() => {
        if (show) {
          dispatch(closeNotification());
          clearTimeout(timeout);
        }
      }, 9000);
    }
  }, [dispatch, iconClose, show]);

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={!iconClose ? handleClose : () => {}}
      >
        <Alert
          onClose={handleClose}
          className={clsx(classes.message, {
            [classes.info]: type === 'info',
            [classes.error]: type === 'error',
            [classes.success]: type === 'success',
            [classes.warning]: type === 'warning'
          })}
        >
          {text}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export default memo(Notification);
