import React, { memo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { ErrorBoundary, book } from '@monorepo/common';

import { Renew } from '../pages/Renew';
import { Cancel } from '../pages/Cancel';
import { Subscribe } from '../pages/Subscribe';
import { SubscriptionComplete } from '../pages/SubscriptionComplete';
import { Issue } from '../pages/Issue';

const RootRouters = () => (
  <>
    <Switch>
      <Route
        exact
        path={book.magazine.renew()}
      >
        <ErrorBoundary>
          <Renew />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={book.magazine.subscribe}
      >
        <ErrorBoundary>
          <Subscribe />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={book.magazine.cancel()}
      >
        <ErrorBoundary>
          <Cancel />
        </ErrorBoundary>
      </Route>
      <Route
        exact
        path={book.magazine.issue()}
      >
        <ErrorBoundary>
          <Issue />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={book.magazine.subscriptionComplete}
      >
        <ErrorBoundary>
          <SubscriptionComplete />
        </ErrorBoundary>
      </Route>
      {/*--------------------------------------*/}
      <Route
        exact
        path="/"
      >
        <Redirect to={book.magazine.subscribe} />
      </Route>
    </Switch>
  </>
);

export default memo(RootRouters);
