import { ReactNode } from 'react';

export interface INotificationReducer {
  type: 'success' | 'error' | 'warning' | 'info',
  text: string | ReactNode,
  show?: boolean,
  iconClose?: boolean
}
export const notificationInitialState:INotificationReducer = {
  type: 'info',
  text: '',
  show: false,
  iconClose: false
};
