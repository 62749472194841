import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { temporaryDataInitialState, ITemporaryDataReducer, TypePage } from './temporaryDataInitialState';

export const slice = createSlice({
  name: 'temporaryData',
  initialState: temporaryDataInitialState,
  reducers: {
    toggleSkipStartPage: (state:ITemporaryDataReducer, action:PayloadAction<boolean>): void => {
      state.skipStartPage = action.payload;
    },
    displayTypePage: (state:ITemporaryDataReducer, action:PayloadAction<TypePage>): void => {
      state.typePage = action.payload;
    },
  },
});

export const { toggleSkipStartPage, displayTypePage } = slice.actions;
export default slice.reducer;
