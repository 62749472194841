import React, { useCallback, useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@mui/material';
import clsx from 'clsx';

import FormAutocompleteWrapper from './components/FormAutocompleteWrapper';
import { FormAutocompleteProps } from './formAutocompleteInterface';
import { FieldComment, formatterLabel } from '../FormFieldUtils';
import { IAutocompleteOption } from '../../interfaces';
import Loader from '../Loader';

import { useStyles } from './styles';

export const FormAutocomplete = <T extends unknown>({
  originalName,
  onInput,
  isDisabled = false,
  isRequired,
  label,
  openOnFocus,
  selectSettings = {
    clearOnBlur: true,
    autoSelect: false,
    multiple: false,
    setValueByKey: 'value'
  },
  disableClearable = false,
  isReset,
  styleProps,
  defaultValue,
  getValues,
  hideButtonClear = false,
  options = [],
  placeholder,
  fieldComment,
  onChange,
  setValue,
  onBlur,
  errors = false,
  extraProps,
  optionsSettings,
  isLoading = false,
}:FormAutocompleteProps<T>) => {
  const classes = useStyles();
  const [selectValue, setSelectValue] = useState<IAutocompleteOption | null | IAutocompleteOption[] | any>(null);
  const [count, setCount] = useState<number>(0);
  const [messageFromExtraError, setMessageFromExtraError] = useState<string | null>(null);

  const change = (event: any, value: T | T[]) => {
    setSelectValue(value);
    if (onChange) {
      onChange(value);
    }
    if (!selectSettings.multiple && !Array.isArray(value)) {
      if (setValue && extraProps) {
        setValue(extraProps.name, value ? value[optionsSettings.optionValue] : null, {
          shouldTouch: true,
          shouldDirty: true
        });
      }
    }
    if (selectSettings.multiple && Array.isArray(value)) {
      if (setValue && extraProps) {
        if (value && value.length) {
          const temporaryArray:any[] = [];
          value.forEach((item:T) => {
            temporaryArray.push(item[optionsSettings.optionValue]);
          });
          setValue(extraProps.name, temporaryArray);
          return;
        }
        setValue(extraProps.name, []);
      }
    }
  };

  const blur = useCallback(() => {
    if (onBlur) {
      onBlur(selectValue);
    }
  }, [onBlur, selectValue]);

  const inputHandler = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (onInput) {
      onInput(value);
    }
  };

  // ----------------------------------------------- for default data (with react-hook-form)
  useEffect(() => {
    if (extraProps) {
      // ----------------------------------------------- for single mode (with react-hook-form)
      if (!selectSettings.multiple && getValues && getValues(extraProps.name) && extraProps && count === 0 && options?.length) {
        const result: T | undefined = options?.find((item: T) => {
          const innerItemValue: any = item[optionsSettings.optionValue];
          const innerItemText: any = item[optionsSettings.optionText];

          if ((innerItemValue && innerItemValue.toString()
            .toLowerCase() === getValues(extraProps.name)
            .toString()
            .toLowerCase()) || (innerItemText && innerItemText.toString()
            .toLowerCase() === getValues(extraProps.name)
            .toString()
            .toLowerCase())) {
            return item;
          }
          return null;
        });

        setSelectValue(result || null);
        setCount(1);
      }
      // ----------------------------------------------- for multiple mode (with react-hook-form)
      if (selectSettings.multiple && getValues && getValues(extraProps.name)?.length && extraProps && count === 0 && options?.length) {
        const result: T[] = [];

        getValues(extraProps.name)
          .forEach((item: string) => {
            options.forEach((el) => {
              const innerItem: any = el[optionsSettings.optionValue];
              if (innerItem && (innerItem.toString() === item.toString())) {
                result.push(el);
              }
            });
          });
        setSelectValue(result);
        setCount(1);
      }
    }
  }, [extraProps, getValues, count, selectSettings, options, optionsSettings.optionValue]);

  useEffect(() => {
    if (isReset) {
      setSelectValue(null);
    }
  }, [isReset]);

  // ----------------------------------------------- for default data
  useEffect(() => {
    if (defaultValue) {
      setSelectValue(defaultValue);
    }
  }, []);

  useEffect(() => {
    const onKey: string[] = Object.keys(errors);
    if (onKey.length && originalName && errors[originalName]) {
      setMessageFromExtraError(errors[originalName].message);
      return;
    }
    setMessageFromExtraError(null);
  }, [errors, extraProps, originalName]);

  return (
     <>
        <FormAutocompleteWrapper
           hideButtonClear={hideButtonClear}
           classNameForWrap={clsx(styleProps?.classNameForWrap, {
             [classes.label_exist]: !!label,
           })}
        >
           <Loader
              size={30}
              isShow={isLoading}
              position="absolute"
           />
           <Autocomplete
              {...(openOnFocus) && { openOnFocus }}
              fullWidth
              onBlur={blur}
              onChange={change}
              disabled={isDisabled}
              options={options || []}
              onInput={inputHandler}
              disableClearable={disableClearable}
              multiple={selectSettings?.multiple}
              autoSelect={selectSettings?.autoSelect}
              getOptionLabel={(option) => option[optionsSettings.optionText] || 'No title'}
              clearOnBlur={selectSettings?.clearOnBlur}
              className={clsx((styleProps && styleProps.classNameForSelect))}
              value={selectSettings?.multiple && selectValue === null ? [] : selectValue}
              renderInput={(params) => (
                 <TextField
                    placeholder={placeholder}
                    variant="outlined"
                    label={formatterLabel({ label, isRequired })}
                    error={(extraProps && !selectValue && (!!errors[extraProps.name])) || (!!messageFromExtraError)}
                    {...params}
                    helperText={(extraProps && !selectValue && (errors[extraProps.name] && errors[extraProps.name].message)) || ((messageFromExtraError))}
                    InputProps={{
                      ...params.InputProps,
                      inputProps: {
                        ...params.inputProps,
                        ...(extraProps?.name) && { 'data-cy': extraProps.name }
                      }
                    }}
                 />
              )}
           />
           <FieldComment
              fieldComment={fieldComment}
              className={styleProps?.classNameForLabel}
           />
        </FormAutocompleteWrapper>
     </>
  );
};

export default FormAutocomplete;
