import React, { memo, useCallback, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { GlobalInterfaces } from '@monorepo/common';

import Header from '../../components/Header';
import LeftSideBar from './components/LeftSideBar';
import GeneralOverlay from './components/GeneralOverlay';
import { toggleMobileMenu } from '../../features/ui/sliceMobileMenu';
import { useAppSelector } from '../../store/hooks';

import { useStyles } from './styles';

interface IGeneralLayout extends GlobalInterfaces.IChildrenProps{
  noMenu?: boolean
}

const Layout = ({ children, noMenu }:IGeneralLayout) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { isMobileMenu } = useAppSelector(({ ui }) => ({
    isMobileMenu: ui.isMobileMenu
  }));

  const toggleMenu = useCallback(() => {
    dispatch(toggleMobileMenu(!isMobileMenu));
  }, [isMobileMenu]);

  useEffect(() => {
    dispatch(toggleMobileMenu(false));
  }, [pathname]);

  return (
    <div className={classes.root}>
      {!noMenu && (
        <LeftSideBar
          toggleOpen={toggleMenu}
        >
          menu
        </LeftSideBar>
      )}

      <main className={clsx(classes.content)}>
        <CssBaseline />
        <Header />
        <div className={clsx(classes.work_zone, {
          [classes.menuOpen]: isMobileMenu
        })}
        >
          {children}
        </div>
        {isMobileMenu && <GeneralOverlay />}
      </main>
    </div>
  );
};

export default memo(Layout);
