import React from 'react';
import { Typography } from '@mui/material';

interface IStep1LabelProps {
  title: string,
  subtitle: string
}
const Step1Label = ({ subtitle, title }:IStep1LabelProps) : JSX.Element => (
  <>
    <Typography
      fontWeight="bold"
      variant="h6"
    >
      {title}
    </Typography>
    <Typography
      variant="subtitle2"
    >
      {subtitle}
    </Typography>
  </>
);

export default Step1Label;
