import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import notification from '../features/sliceNotification';
import ui from '../features/ui/sliceMobileMenu';
import temporaryData from '../features/sliceTemporaryData';
import stepperOptions from '../features/sliceStepper';
import { middlewareHandler } from './middlewareList';
import { magazineApi } from '../services/serviceMagazine';

export const store = configureStore({
  middleware: (getDefaultMiddleware) => middlewareHandler(getDefaultMiddleware),
  reducer: {
    stepperOptions,
    temporaryData,
    notification,
    ui,
    [magazineApi.reducerPath]: magazineApi.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
