import React, { memo, useEffect, useRef } from 'react';
import { Button, Card, Checkbox, FormGroup, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import cookies from 'js-cookie';

import { book, cookieNames, Loader } from '@monorepo/common';

import { useLazyClearTempDataQuery, usePostSubscribeMutation } from '../../../../services/serviceMagazine';
import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { IEventsStepProps, IStep2data, IStep3Payment } from '../../stepsInterfaces';
import { IStep1Response } from '../../../../services/serviceMagazine/interfaces';
import { displayTypePage } from '../../../../features/sliceTemporaryData';
import { showNotification } from '../../../../features/sliceNotification';
import { SETTINGS } from '../../../../constants/constants';
import AddressInfo from '../../components/AddressInfo';

import { useStyles } from '../../styles';

interface IFinalStepProps extends IEventsStepProps{
  finalStep: boolean,
  step1Data?: IStep1Response | null,
  step2data: IStep2data | null,
  setFinalStep: (val: boolean) => void,
  Nav: React.ElementType,
  step3: IStep3Payment
}

const FinalStep = ({ onBack, finalStep, setFinalStep, step1Data, step2data, Nav, step3 }:IFinalStepProps) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [postSubscribe, { isLoading, error, isSuccess }] = usePostSubscribeMutation();
  const [startClearTempStorage, { data: resultCleared, isLoading: isClearing }] = useLazyClearTempDataQuery();

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const saveData = async () => {
    if (recaptchaRef && recaptchaRef.current) {
      const captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      if (captchaToken) {
        if (step1Data && step2data) {
          await postSubscribe({
            step1: {
              subscriptionType: step1Data.value
            },
            step2: {
              ...step2data,
              country: step2data.country === 'US' ? 'USA' : step2data.country,
            },
            step3,
            captchaToken,
          });
        }
        return;
      }
      dispatch(showNotification({
        show: true,
        type: 'error',
        text: 'Captcha token not found'
      }));
    }
  };

  useEffect(() => {
    if (isSuccess && cookies.get(cookieNames.tempDataId)) {
      startClearTempStorage(cookies.get(cookieNames.tempDataId) as string);
    }

    if (error && ('data' in error)) {
      const { message } = error.data as { message: string };
      dispatch(showNotification({
        show: true,
        type: 'error',
        text: message
      }));
    }
  }, [isSuccess, history, error]);
  useEffect(() => {
    if (resultCleared) {
      cookies.remove(cookieNames.tempDataId);
      dispatch(displayTypePage('subscription'));
      history.push(book.magazine.subscriptionComplete);
    }
  }, [resultCleared]);

  return (
    <div data-cy="final_step">
      <Card className={classes.wrapper_card}>
        <Loader isShow={isLoading || isClearing} />
        <ReCAPTCHA
          style={{
            visibility: 'hidden'
          }}
          ref={recaptchaRef}
          size="invisible"
          sitekey={SETTINGS.RECAPTCHA_PUBLIC_KEY}
        />
        <Typography
          sx={{ mb: 3 }}
          variant="body2"
        >
          You have selected a
          {' '}
          <b data-cy="step1_value">{step1Data?.title}</b>
          {' '}
          subscription to be sent to:
        </Typography>

        {step2data && (
          <AddressInfo
            data={step2data}
            type={step1Data || null}
          />
        )}

        <FormGroup className={classes.step3_checkbox_line}>
          <FormControlLabel
            checked={finalStep}
            onChange={(a, b:boolean) => {
              setFinalStep(b);
            }}
            control={(
              <Checkbox />
)}
            label={(
              <Typography variant="body2">
                Yes, I would like to receive BevNET Magazine
              </Typography>
          )}
          />
        </FormGroup>

        <Typography
          sx={{ mb: 3 }}
          variant="body2"
          fontWeight="bold"
        >
          Press continue to finalize your subscription.
        </Typography>

        <Nav
          nextDisabled={false}
          onBack={onBack}
          nextButton={(
            <Button
              type="button"
              disabled={!finalStep}
              onClick={saveData}
              variant="contained"
              endIcon={(<ArrowRightIcon />)}
            >
              Continue
            </Button>
            )}
        />
      </Card>
    </div>
  );
};
export default memo(FinalStep);
