import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { book, BasicLayout } from '@monorepo/common';

import { useAppSelector } from '../../store/hooks';
import { useStyles } from './styles';
import { ForRenew } from './components/ForRenew';
import { ForSubscribe } from './components/ForSubscribe';

// interface ICancelProps {}
export const SubscriptionComplete = () => {
  const classes = useStyles();
  const history = useHistory();
  const [pageTitle, setPageTitle] = useState<string>('');

  const { typePage } = useAppSelector(({ temporaryData }) => ({
    typePage: temporaryData.typePage
  }));

  useEffect(() => {
    if (!typePage) {
      history.push(book.magazine.subscribe);
    }
    if (typePage === 'subscription') {
      setPageTitle('Your Free Subscription is Confirmed!');
    }
    if (typePage === 'renew') {
      setPageTitle('Renewal Complete');
    }
  }, [typePage]);

  return (
    <BasicLayout
      helmet={pageTitle}
    >
      <div className={classes.root}>
        <div className={classes.wrap}>
          {typePage === 'subscription' && <ForSubscribe />}

          {typePage === 'renew' && <ForRenew />}
        </div>
      </div>
    </BasicLayout>
  );
};
