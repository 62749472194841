import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotificationReducer, notificationInitialState } from './notificationInitialState';

export const slice = createSlice({
  name: 'notification',
  initialState: notificationInitialState,
  reducers: {
    showNotification: (state:INotificationReducer, action:PayloadAction<INotificationReducer>): void => {
      if (action.payload.text) {
        state.show = true;
        state.type = action.payload.type;
        state.text = action.payload.text;
        state.iconClose = action.payload.iconClose;
      }
    },
    closeNotification: (state: INotificationReducer) => {
      state.show = false;
      state.type = 'info';
      state.text = '';
      state.iconClose = false;
    }
  },
});
export const { showNotification, closeNotification } = slice.actions;
export default slice.reducer;
