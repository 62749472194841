import { IStep2data, IStep3Payment } from './stepsInterfaces';

export const staticValueForStep2:IStep2data = {
  fax: '',
  email: '',
  name: '',
  company: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  province: '',
  country: 'US',
  title: '',
  zipCode: '',
  phone: '',
  primaryBusiness: '',
  roleInCompany: '',
  subscriptionType: 0
};
export const staticValueForStep3: IStep3Payment = {
  billingFirstName: '',
  billingLastName: '',
  billingCompany: '',
  billingEmail: '',
  billingPhone: '',

  billingCountry: 'US',
  billingState: '',
  billingProvince: '',
  billingAddress1: '',
  billingAddress2: '',
  billingCity: '',
  billingZipcode: '',

  ccNumber: '',
  ccExpMonth: '',
  ccExpYear: '',
  cccvv: '',
};
