import { makeStyles } from '@material-ui/core';
// import { mixin } back '../../utils/mixin';

export const useStyles = makeStyles(() => ({
  message: {
    whiteSpace: 'pre',
    alignItems: 'flex-start',
    '& a': {
      color: 'inherit',
      '&:hover': {
        color: 'red'
      }
    }
  },
  info: {
    background: 'rgb(229, 246, 253)',
    color: 'rgb(1, 67, 97)'
  },
  success: {
    background: 'rgb(237, 247, 237)',
    color: 'rgb(30, 70, 32)'
  },
  warning: {
    background: 'rgb(255, 244, 229)',
    color: 'rgb(102, 60, 0)'
  },
  error: {
    background: 'rgb(253, 237, 237)',
    color: 'rgb(95, 33, 32)'
  },
}));
