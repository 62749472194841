import React from 'react';

import Layout from './containers/Layout';
import RootRouters from './routers/RootRouters';
import { useAppSelector } from './store/hooks';
import Notification from './components/Notification';

function App() : JSX.Element {
  const { showNotification } = useAppSelector(({ notification }) => ({
    showNotification: notification.show
  }));

  return (
    <>
      {showNotification && <Notification />}
      <Layout noMenu>
        <RootRouters />
      </Layout>
    </>
  );
}

export default App;
