import React, { useEffect, useState, useRef } from 'react';
import { Button } from '@mui/material';
import { book, Loader } from '@monorepo/common';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { showNotification } from '../../../features/sliceNotification';
import AddressInfo from '../../../components/AddressInfo/AddressInfo';
import { IIssue, IStep1Response } from '../../../services/serviceMagazine/interfaces';
import { useGetSubscriptionTypesQuery, usePutRenewDataMutation } from '../../../services/serviceMagazine';
import { SETTINGS } from '../../../constants/constants';
import { useStyles } from '../../Renew/styles';
import { TitleInfo } from './TitleInfo';

interface IDetailsConfirmationProps {
  data: IIssue | undefined,
  slug: string,
  id: string,
}

export const DetailsConfirmation = ({ data: userData, slug, id }:IDetailsConfirmationProps) : JSX.Element => {
  const classes = useStyles();
  const [subscribeType, setSubscribeType] = useState<IStep1Response | null>(null);
  const { isLoading: loadingTypes, data: typesData } = useGetSubscriptionTypesQuery(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [putRenewData, { data: result, isLoading, isSuccess, error }] = usePutRenewDataMutation();
  const responded = userData?.circulationDetails?.responded ?? false;

  const saveData = async () => {
    if (recaptchaRef && recaptchaRef.current) {
      const captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      if (userData?.circulationDetails && captchaToken) {
        await putRenewData({
          ...userData.circulationDetails,
          country: userData.circulationDetails.country === 'US' ? 'USA' : userData.circulationDetails.country,
          captchaToken
        });
        return;
      }
    }
    dispatch(showNotification({
      show: true,
      type: 'error',
      text: 'Captcha token not found'
    }));
  };

  const goRenew = () => {
    history.push(book.magazine.renew(id, slug));
  };

  useEffect(() => {
    if (result && result.success && !error) {
      history.push(book.magazine.subscriptionComplete);
    }

    if (error && ('data' in error)) {
      const { message } = error.data as { message: string };
      dispatch(showNotification({
        show: true,
        type: 'error',
        text: message
      }));
    }
  }, [result, history, error]);

  useEffect(() => {
    if (userData && userData.circulationDetails && typesData) {
      const res:IStep1Response | undefined = typesData.find((item:IStep1Response) => item.value === userData?.circulationDetails?.subscriptionType);
      setSubscribeType(res || null);
    }
  }, [typesData, userData]);

  useEffect(() => {
    if (isSuccess || responded) {
      window.location.href = `https://issuu.com/bevnet/docs/${slug}`;
    }
  }, [isSuccess, responded]);

  if (loadingTypes) {
    return (
      <Loader isShow={loadingTypes} />
    );
  }

  if (!loadingTypes && (userData?.gridDetails === null)) {
    return (
      <div
        className={classes.start_magazine_page}
        data-cy="wrong_slug"

      >
        <TitleInfo
          text="Sorry, the issue you&apos;ve clicked is invalid."
          color="red"
        />
      </div>
    );
  }

  if (!loadingTypes && (userData?.circulationDetails === null)) {
    return (
      <div
        className={classes.start_magazine_page}
        data-cy="wrong_id"
      >
        <TitleInfo
          text="Sorry, your subscription ID is invalid."
          color="red"
        />
      </div>
    );
  }

  if (userData?.circulationDetails) {
    return (
      <div
        className={classes.start_magazine_page}
        data-cy="issue_start_page"
      >
        <Loader isShow={isLoading} />
        <ReCAPTCHA
          style={{
            visibility: 'hidden'
          }}
          size="invisible"
          ref={recaptchaRef}
          sitekey={SETTINGS.RECAPTCHA_PUBLIC_KEY}
        />
        <TitleInfo
          text={`View the ${userData.gridDetails?.name} Issue`}
          color="black"
        />

        <AddressInfo
          data={userData.circulationDetails}
          type={subscribeType}
        />

        <div className={classes.line}>
          <Button
            variant="outlined"
            onClick={goRenew}
          >
            UPDATE SUBSCRIPTION DETAILS
          </Button>

          <Button
            variant="contained"
            onClick={saveData}
          >
            The above information is correct + view the issue
          </Button>
        </div>
      </div>
    );
  }
  return null;
};
