import React from 'react';
import { Typography } from '@mui/material';

// interface IForSubscribeProps {}
export const ForSubscribe = () => (
  <>
    <Typography
      sx={{ mb: 3 }}
      variant="h4"
      fontWeight="bold"
    >
      Your Free Subscription is Confirmed!
    </Typography>

    <Typography
      variant="body1"
      marginBottom={2}
    >
      Thank you for signing up to receive BevNET Magazine.
      Your free subscription is confirmed. Note that it may take up to 8 weeks before you receive your first issue.
      If you chose a digital subscription, please whitelist
      {' '}
      <a href="mailto:magazine@bevnet.com">magazine@bevnet.com</a>
      {' '}
      in your email client.
    </Typography>
    <Typography
      variant="body1"
    >
      In the meantime, check out the latest edition of BevNET Magazine
      {' '}
      <a
        href="https://www.bevnet.com/magazine"
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>
      .
    </Typography>
  </>
);
