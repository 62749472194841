import { makeStyles } from '@material-ui/core';

import { Mixin } from '@monorepo/common';

export const useStyles = makeStyles((theme) => ({
  wrapper_card: {
    padding: '40px 20px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius
  },
  table_wrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
    ...Mixin.mixin.br()
  },
  link: {
    color: theme.palette.error.main
  },
  jobs_line_buttons: {
    marginTop: '2rem',
    ...Mixin.mixin.flex('center', 'flex-start'),
    minHeight: 30,
    '@media(max-width: 600px)': {
      flexDirection: 'column'
    }
  },
  previous_btn: {
    '@media(max-width: 600px)': {
      marginLeft: '0 !important',
      marginTop: ' 15px !important'
    }
  },

  step3_address_wrap: {
    ...Mixin.mixin.flex('flex-start', 'flex-start'),
    gridGap: '2rem',
    marginBottom: '2rem',
    '& > div': {
      flex: 1,
      marginBottom: 0,
      '@media(max-width: 768px)': {
        marginBottom: '2rem'
      }
    },
    '@media(max-width: 768px)': {
      display: 'block'
    }
  },
  stripe_field: {
    marginBottom: '2rem',
    position: 'relative',
    borderRadius: theme.shape.borderRadius / 2,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    }
  },
  stripe_field_label: {
    position: 'absolute',
    background: '#fff',
    padding: '0 6px',
    top: '-12px',
    left: 8,
    fontWeight: 400,
    fontSize: '0.8rem',
    zIndex: 1,
    color: 'rgba(0, 0, 0, 0.6)',
    pointerEvents: 'none'
  },
  card_99: {
    padding: '0 14px',
  },
  card_with_icon: {
    '& svg': {
      opacity: '1 !important',
      transform: 'scale(1) !important'
    }
  },
  // ----------------------------
  step_1_wrapper: {
    width: '100%',
    '& $step_1_item': {
      margin: 0,
      padding: 20,
      alignItems: 'flex-start',
      '&:hover:not(&$none_hover), &$active': {
        background: 'rgba(0, 0, 0, .1)'
      }
    }
  },
  step_1_item: {},
  active: {},
  none_hover: {
    cursor: 'default !important'
  },
  step3_checkbox_line: {
    margin: '20px 0 20px'
  },
  start_magazine_page: {
    width: '100%',
    maxWidth: '40rem',
    margin: '0 auto'
  },
  line: {
    ...Mixin.mixin.flex('flex-start'),
    flexDirection: 'column',
    gridGap: 20
  }
}));

export const stripeFieldOptions = {
  showIcon: true,
  style: {
    iconStyle: 'solid',
    base: {
      padding: '15px',
      lineHeight: '56px',
    },
  }
};
