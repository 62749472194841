import { Typography } from '@mui/material';

interface ITitleProps {
  text: string,
  color: string
}

export const TitleInfo = ({ text, color }: ITitleProps) : JSX.Element => (
  <>
    <Typography
      variant="h4"
      fontWeight="bold"
      color={color}
    >
      BevNET Magazine:
    </Typography>
    <Typography
      sx={{ mb: 3 }}
      variant="h4"
      fontWeight="bold"
      color={color}
    >
      {text}
    </Typography>
  </>
);
