import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { cookieNames } from '@monorepo/common';
import cookies from 'js-cookie';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import endpoints from '../../api/endpoints';
import {
  ICancel,
  IRenewStep2Response,
  IStep1Response,
  IStep2SelectOptionsResponse,
  ISubscribeBodyRequest,
  ITempDataRequest,
  IUserDataResponse,
  IIssue
} from './interfaces';

export const magazineApi = createApi({
  reducerPath: 'Magazine',
  tagTypes: ['renew_data'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getSubscriptionTypes: build.query<IStep1Response[], null>({
      query: () => ({
        url: endpoints.magazine.subscriptionTypes
      }),
    }),
    // ----- select`s options
    getPrimaryBusinessTypes: build.query<IStep2SelectOptionsResponse[], null>({
      query: () => ({
        url: endpoints.magazine.primaryBusinessTypes
      }),
    }),
    getRolesInCompany: build.query<IStep2SelectOptionsResponse[], null>({
      query: () => ({
        url: endpoints.magazine.rolesInCompany
      }),
    }),

    getRenewData: build.query<IRenewStep2Response, { id: string }>({
      query: ({ id }) => ({
        url: endpoints.magazine.renew(id)
      }),
      providesTags: () => ['renew_data']
    }),

    getCancelData: build.query<ICancel, string>({
      query: (id) => ({
        url: endpoints.magazine.cancel(id)
      })
    }),

    getUserInfo: build.query<IUserDataResponse, void>({
      query: () => ({
        url: endpoints.magazine.userInfo
      })
    }),
    getIssueData: build.query<IIssue, { slug: string, id: string }>({
      query: ({ slug, id }) => ({
        url: endpoints.magazine.issue(slug, id)
      }),
    }),

    postCancel: build.mutation<{ success: boolean }, ICancel>({
      query: (body) => ({
        url: endpoints.magazine.postCancel,
        method: 'POST',
        body
      }),
    }),

    postSubscribe: build.mutation<{ success: boolean }, ISubscribeBodyRequest>({
      query: (body) => ({
        url: endpoints.magazine.subscribe,
        method: 'POST',
        body
      }),
    }),
    // --------- temp data
    saveStepsTempData: build.mutation<{ guid: string }, ITempDataRequest>({
      query: (body) => {
        if (cookies.get(cookieNames.tempDataId)) {
          return {
            url: endpoints.magazine.saveTempData(),
            method: 'POST',
            params: { guid: cookies.get(cookieNames.tempDataId) },
            body
          };
        }
        return {
          url: endpoints.magazine.saveTempData(),
          method: 'POST',
          body
        };
      }

    }),

    getStepsTempData: build.query<ITempDataRequest, string>({
      query: (id: string) => ({
        url: endpoints.magazine.getTempData(id),
      }),
    }),

    clearTempData: build.query<ITempDataRequest, string>({
      query: (id) => ({
        url: endpoints.magazine.clearTempData(id),
      }),
    }),

    // --------- temp data   \

    putRenewData: build.mutation<{ success: boolean }, IRenewStep2Response>({
      query: (body) => ({
        url: endpoints.magazine.saveRenew,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['renew_data']
    }),
  }),
});
export const {
  useGetRenewDataQuery,
  useGetCancelDataQuery,
  useGetRolesInCompanyQuery,
  useGetSubscriptionTypesQuery,
  useGetPrimaryBusinessTypesQuery,
  useGetIssueDataQuery,

  useLazyGetUserInfoQuery,
  usePostSubscribeMutation,

  // temp data
  useSaveStepsTempDataMutation,
  useLazyGetStepsTempDataQuery,
  useLazyClearTempDataQuery,

  usePostCancelMutation,
  usePutRenewDataMutation
} = magazineApi;
