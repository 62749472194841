import React from 'react';
import { Typography } from '@mui/material';

// interface IForRenewProps {}
export const ForRenew = () => (
  <>
    <Typography
      sx={{ mb: 3 }}
      variant="h4"
      fontWeight="bold"
    >
      Renewal Complete
    </Typography>

    <Typography
      variant="body1"
    >
      Your renewal request. Thank you.
    </Typography>
  </>
);
