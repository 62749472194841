import { Loader } from '@monorepo/common';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { DetailsConfirmation } from './components/DetailsConfirmation';
import { useGetIssueDataQuery } from '../../services/serviceMagazine';

export const Issue = () : JSX.Element => {
  const { slug, id } = useParams<{ slug: string, id: string }>();
  const { data: userData, isLoading } = useGetIssueDataQuery({ slug, id }, {
    skip: !id && !slug
  });

  return (
    <>
      <Loader isShow={isLoading} />
      <HelmetProvider>
        <Helmet>
          <title>
            BevNET Magazine: Issue Redirect
          </title>
        </Helmet>
      </HelmetProvider>

      <div data-cy="page_issue">
        <DetailsConfirmation
          data={userData}
          slug={slug}
          id={id}
        />
      </div>
    </>
  );
};
