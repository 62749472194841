import { makeStyles } from '@material-ui/core';
// import { mixin } from '../../utils/mixin';

// '@media(max-width: 768px)': {},
export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: '60rem',
    margin: '0 auto',
    '& img': {
      width: '100%',
      marginBottom: 10
    },
  },
  wrap: {
    padding: 25
  },
  btn: {
    margin: '30px 0 0 !important'
  },
  btn2: {
    display: 'block !important',
    margin: '15px 0 30px !important'
  },
}));
