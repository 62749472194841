import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card } from '@mui/material';

import {
  FormField,
  useHookForm,
  useIsProvince,
  GlobalInterfaces,
  FormAutocomplete,
  CountryAutocomplete,
  UsaStatesAutocomplete,
  useCustomGoogleAutocomplete,
} from '@monorepo/common';

import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { IEventsStepProps, IStep3Payment } from '../../stepsInterfaces';
import { dateConverter } from './dateConverter';
import { schema } from './schema';
import { useStyles } from '../../styles';

interface IStepPaymentProps extends IEventsStepProps{
  Nav: React.ElementType,
  step3: IStep3Payment | null,
  setStep3: (e:IStep3Payment) => void,
}

const Step3 = ({ Nav, onBack, onNext, setStep3, step3 }:IStepPaymentProps) => {
  const classes = useStyles();
  const [changedFields, setChangedFields] = useState<IStep3Payment | null>(null);
  const [countryCode, setCountryCode] = useState<string | null>(null);

  const [optionMonth, setOptionMonth] = useState<GlobalInterfaces.IOption[]>([]);
  const [optionYears, setOptionYears] = useState<GlobalInterfaces.IOption[]>([]);

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    errors
  } = useHookForm<IStep3Payment>({
    schema,
    defaultValues: step3 || null,
    ifErrorScrollPageToUp: true,
    showChangedFields: (fields) => {
      setChangedFields(fields);
    }
  });

  const goBack = () => {
    if (onBack) {
      if (changedFields && (JSON.stringify(step3).toString() !== JSON.stringify(changedFields))) {
        setStep3(changedFields);
      }
      onBack();
    }
  };

  const { getData, isProvince } = useIsProvince({
    defaultProvince: !((step3?.billingCountry === 'US' || step3?.billingCountry === 'USA')),
    extraOptions: {
      setValue,
      stateFieldName: 'billingState',
      provinceFieldName: 'billingProvince'
    }
  });
  const { changeInput, renderedList, clearSuggestions } = useCustomGoogleAutocomplete({
    country: countryCode || '',
    mode: 'custom',
    setValue,
    autoFillFields: {
      state: 'billingState',
      province: 'billingProvince',
      address1: 'billingAddress1',
      city: 'billingCity',
      zip: 'billingZipcode'
    }
  });

  const onSubmit = (data: IStep3Payment) => {
    if (data && onNext) {
      setStep3(data);
      onNext();
    }
  };
  // if choose country
  const changeCountries = (val:GlobalInterfaces.IAutocompleteOption | null) => {
    clearSuggestions();
    getData({
      selectOption: val
    });
    setCountryCode(val?.alpha2 || null);
  };

  useEffect(() => {
    setValue('isShowBillingState', !isProvince);
  }, [isProvince]);

  useEffect(() => {
    dateConverter({
      type: 'years',
      setData: setOptionYears
    });

    dateConverter({
      type: 'month',
      setData: setOptionMonth
    });
  }, []);

  return (
    <Card className={classes.wrapper_card}>
      <form
        data-cy="step3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <>
          <div className={classes.step3_address_wrap}>
            <FormField
              isRequired
              errors={errors}
              label="Billing Name"
              placeholder="First Name"
              extraProps={{ ...register('billingFirstName') }}
            />
            <FormField
              isRequired
              errors={errors}
              placeholder="Last Name"
              label="Billing Last Name"
              extraProps={{ ...register('billingLastName') }}
            />
          </div>

          <FormField
            isRequired
            errors={errors}
            label="Billing Company"
            placeholder="Company"
            extraProps={{ ...register('billingCompany') }}
          />
          <FormField
            isRequired
            errors={errors}
            placeholder="Email"
            label="Billing Email"
            extraProps={{ ...register('billingEmail') }}
          />
          <FormField
            isRequired
            errors={errors}
            label="Billing Phone"
            placeholder="Phone"
            extraProps={{ ...register('billingPhone') }}
          />

          <CountryAutocomplete
            isRequired
            errors={errors}
            label="Country"
            setValue={setValue}
            disableClearable
            getValues={getValues}
            handleChange={changeCountries}
            extraProps={{ ...register('billingCountry') }}
          />
          <FormField
            isRequired
            errors={errors}
            label="Address"
            placeholder="Address"
            extraProps={{ ...register('billingAddress1') }}
            eventInput={(inputValue, event) => {
              changeInput(event as ChangeEvent<HTMLInputElement>);
            }}
          >
            {renderedList}
          </FormField>
          <FormField
            errors={errors}
            label="address2"
            extraProps={{ ...register('billingAddress2') }}
            placeholder="Apartment, suite, unit, building, floor, etc."
          />

          <div className={classes.step3_address_wrap}>
            <FormField
              isRequired
              label="City"
              errors={errors}
              placeholder="City"
              extraProps={{ ...register('billingCity') }}
            />
            <FormField
              errors={errors}
              label="Province"
              visible={isProvince}
              placeholder="Province"
              extraProps={{ ...register('billingProvince') }}
            />
            <UsaStatesAutocomplete
              isRequired
              label="State"
              errors={errors}
              disableClearable
              visible={!isProvince}
              getValues={getValues}
              setValue={setValue}
              extraProps={{ ...register('billingState') }}
            />
          </div>
        </>

        <FormField
          isRequired
          errors={errors}
          label="ZIP/Postal"
          extraProps={{ ...register('billingZipcode') }}
        />
        <FormField
          fieldMask="9999-9999-9999-9999"
          isRequired
          type="number"
          errors={errors}
          label="Card"
          extraProps={{ ...register('ccNumber') }}
        />

        <div className={classes.step3_address_wrap}>
          <FormAutocomplete<GlobalInterfaces.IOption>
            optionsSettings={{
              optionText: 'text',
              optionValue: 'value'
            }}
            isRequired
            disableClearable
            label="Month"
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            options={optionMonth}
            placeholder="Select value"
            extraProps={{ ...register('ccExpMonth') }}
          />
          <FormAutocomplete<GlobalInterfaces.IOption>
            optionsSettings={{
              optionText: 'text',
              optionValue: 'value'
            }}
            disableClearable
            isRequired
            label="Year"
            errors={errors}
            setValue={setValue}
            options={optionYears}
            getValues={getValues}
            placeholder="Select value"
            extraProps={{ ...register('ccExpYear') }}
          />
        </div>
        <FormField
          isRequired
          label="CVV"
          type="password"
          errors={errors}
          extraProps={{ ...register('cccvv') }}
        />

        <Nav
          nextDisabled={false}
          onBack={goBack}
          nextButton={(
            <Button
              type="submit"
              variant="contained"
              endIcon={(<ArrowRightIcon />)}
            >
              Next Step
            </Button>
          )}
        />
      </form>
    </Card>
  );
};

export default Step3;
