import React, { memo, useEffect } from 'react';
import clsx from 'clsx';
import {
  Card
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { PlugNoData } from '@monorepo/common';
import Step1Label from './Step1Label';
import { IEventsStepProps } from '../../stepsInterfaces';
import { IStep1Response } from '../../../../services/serviceMagazine/interfaces';

import { useStyles } from '../../styles';

interface IStep1Props extends IEventsStepProps{
  disabledNext: boolean,
  Nav: React.ElementType,
  data: IStep1Response[] | null | undefined,
  activeId: IStep1Response | null,
  setStep: (a:IStep1Response) => void,
  setIsTemporaryDisableRequest: (a:boolean) => void,
  country: string | 'USA' | 'US'
}
const Step1 = ({ onNext, setStep, activeId, Nav, country, data = null, setIsTemporaryDisableRequest, disabledNext }:IStep1Props) => {
  const classes = useStyles();

  useEffect(() => {
    if (activeId && data) {
      const result:IStep1Response | undefined = data?.find((item:IStep1Response) => item.value === activeId.value);
      if (result) {
        setStep(result);
        setIsTemporaryDisableRequest(false);
      }
    }
  }, [activeId, data]);

  return (
    <div data-cy="step1">
      <Card>
        <FormControl
          className={classes.step_1_wrapper}
          id="step1-fields"
        >
          {!data && (
            <PlugNoData />
          )}
          <RadioGroup
            onChange={(b, val) => {
              const result:IStep1Response | undefined = data?.find((item:IStep1Response) => item.value.toString() === val);
              if ((country === 'USA' || country === 'US') && result) setStep(result);
            }}
          >
            {data?.map((item:IStep1Response) => (
              <FormControlLabel
                datatype={item.value.toString()}
                key={item.value}
                className={clsx(classes.step_1_item, {
                  [classes.none_hover]: country !== 'USA' && country !== 'US',
                  [classes.active]: activeId && (activeId.value.toString() === item.value.toString())
                })}
                value={item.value.toString()}
                control={<Radio checked={!!activeId && (activeId.value.toString() === item.value.toString())} />}
                label={(
                  <Step1Label
                    title={item.title}
                    subtitle={item.description}
                  />
                      )}
              />
            ))}

          </RadioGroup>
        </FormControl>
      </Card>

      <Nav
        onNext={onNext}
        nextDisabled={!activeId && !disabledNext}
      />
    </div>
  );
};
export default memo(Step1);
