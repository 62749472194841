import { formattedUrl } from '../utils/formattedUrl';

interface ILinks {
  iframeSrc: string,
  login: string,
  api: string,
  adminApi: string,
  jobApi: string,

}

export const baseUrls:ILinks = {
  login: formattedUrl('https://auth.bevnet.com', 'http://localhost:5000'),

  iframeSrc: formattedUrl('https://auth.bevnet.com/Iframe', 'http://localhost:5000/Iframe'),

  api: formattedUrl('https://api.bevnet.com', 'http://localhost:5003'),

  jobApi: formattedUrl('https://api.bevnet.com', 'https://localhost:5002'),

  adminApi: formattedUrl('https://adminapi.bevnet.com', 'https://localhost:5001'),
};
