export default {
  magazine: {
    subscriptionTypes: '/Magazine/GetMagazineSubscriptionTypes',
    primaryBusinessTypes: 'Magazine/GetPrimaryBusinessTypes',
    rolesInCompany: '/Magazine/GetRolesInCompany',
    renew: (id: string) : string => `/Magazine/Renew/${id}`,
    saveRenew: '/Magazine/Renew',
    cancel: (id: string) : string => `/Magazine/Cancel/${id}`,
    postCancel: '/Magazine/Cancel',
    userInfo: '/Magazine/GetUserInfo',
    subscribe: '/Magazine/Subscribe',

    saveTempData: (id?: string) : string => (id
      ? `Magazine/SaveSubscribeTempData/${id}`
      : 'Magazine/SaveSubscribeTempData'),

    getTempData: (id: string) : string => `Magazine/GetSubscribeTempData/${id}`,

    clearTempData: (id?: string) : string => `Magazine/ClearSubscribeTempData/${id}`,

    issue: (slug: string, id: string) : string => `/Magazine/Issue/${slug}/${id}`,
  }
};
