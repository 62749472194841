import React, { memo, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { book, BasicLayout } from '@monorepo/common';

import {
  useGetCancelDataQuery,
  usePostCancelMutation
} from '../../services/serviceMagazine';
import CancelContent from './components/CancelContent';

import { useStyles } from './styles';

// interface ICancelProps {}
export const Cancel = memo(() => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [postCancel, { isLoading: isCanceling, data: canceled }] = usePostCancelMutation();
  const { data, isLoading, error } = useGetCancelDataQuery(id, {
    skip: !id
  });

  const cancelEvent = () => {
    if (data && data.guid) {
      postCancel({
        guid: data.guid
      });
    }
  };
  useEffect(() => {
    if (error) {
      window.location.href = book.magazine.subscribe;
    }
  }, [error]);

  return (
    <BasicLayout
      helmet="Cancel"
      isLoading={(isLoading && !data) || isCanceling}
    >
      <div
        className={classes.root}
        data-cy="page-cancel"
      >
        <div className={classes.wrap}>
          {!canceled ? (
            <>
              {data && (
              <CancelContent
                cancel={cancelEvent}
                title={data && data.information}
              />
              )}
            </>
          ) : (
            <Typography
              data-cy="notification_after_cancel"
              variant="body2"
            >
              Your subscription has been cancelled. Please note that you will receive any issues already in transit. Thank you.
            </Typography>
          )}
        </div>
      </div>
    </BasicLayout>
  );
});
