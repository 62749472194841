import * as yup from 'yup';

export const schema:any = yup.object().shape({
  isShowState: yup.boolean(),
  country: yup.string().required('Required').nullable(),
  primaryBusiness: yup.string().required('Required').nullable(),
  primaryBusiness_Other: yup.string().required('Required').nullable(),
  name: yup.string().required('Required').nullable(),
  title: yup.string().required('Required').nullable(),
  roleInCompany: yup.string().required('Required').nullable(),
  company: yup.string().required('Required').nullable(),
  email: yup.string().email('The email  field is not valid.').required('Required').nullable(),
  phone: yup.string().required('Required').nullable(),
  fax: yup.string().nullable(),

  address1: yup.string().required('Required').nullable(),
  address2: yup.string().nullable(),
  city: yup.string().required('Required').nullable(),
  state: yup.string().nullable()
    .when('isShowState', {
      is: true,
      then: yup.string().required('Required').nullable(),
      otherwise: yup.string().nullable()
    }),
  province: yup.string().nullable()
    .when('isShowState', {
      is: false,
      then: yup.string().required('Required').nullable(),
      otherwise: yup.string().nullable()
    }),
  zipCode: yup.string().required('Required').nullable(),
});
