import { memo, useCallback, useEffect } from 'react';
import cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';

import { BasicLayout, cookieNames } from '@monorepo/common';

import { useLazyClearTempDataQuery } from '../../../services/serviceMagazine';
import { dropStepper, showDropButton } from '../../../features/sliceStepper';
import { useAppSelector } from '../../../store/hooks';

// interface IDescriptionJobsProps {}
const InfoPanel = () => {
  const dispatch = useDispatch();
  const [startClearTempStorage, { isLoading: isClearing, isFetching }] = useLazyClearTempDataQuery();
  const { isShowDropButton } = useAppSelector((state) => ({
    isShowDropButton: state.stepperOptions.isShowDropButton
  }));
  const drop = useCallback(() => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure that you want to start over? You will not longer be able to edit the current listing.')) {
      if (cookies.get(cookieNames.tempDataId)) {
        startClearTempStorage(cookies.get(cookieNames.tempDataId) as string);
        dispatch(dropStepper({
          isDrop: true
        }));
      }
    }
  }, []);

  useEffect(() => {
    if (isFetching && cookies.get(cookieNames.tempDataId)) {
      cookies.remove(cookieNames.tempDataId);
      dispatch(showDropButton());
      const timeout = setTimeout(() => {
        dispatch(dropStepper({
          isDrop: false
        }));
        clearTimeout(timeout);
      }, 3000);
    }
  }, [isFetching, isClearing]);

  return (
    <BasicLayout isLoading={isClearing}>
      <Typography
        sx={{ mb: 3 }}
        variant="h4"
        fontWeight="bold"
      >
        Subscribe to BevNET Magazine:
        <br />
        Free Industry Analysis & Data
      </Typography>

      <p>
        BevNET Magazine provides comprehensive beverage, food and beer industry coverage 6x per year.
        A free subscription* ensures that you won’t miss important industry data and expert analysis
        that could help you move your business forward.
      </p>

      <ul style={{ marginBottom: 5 }}>
        <li>Explore food and beverage industry insights, analysis, and forecasting</li>
        <li>Utilize top-line IRI and SPINS data as benchmarks for your business</li>
        <li>Stay up-to-date on consumer behavior and emerging trends</li>
        <li style={{ marginBottom: 0 }}>Spot new products across the non-alcoholic, beer, spirits and natural food categories.</li>
      </ul>
      <p>
        Receive your FREE BevNET magazine in print, digital, or both!
      </p>

      <p>
        * Print subscriptions are free to USA addresses; $100 per year for Canada/Mexico;
        $225 per year for all other countries. Subscriptions can be canceled at any time,
        but refunds will not be given for unused portions of paid subscriptions.
      </p>
      {isShowDropButton && (
      <Typography
        color="red"
        style={{
          cursor: 'pointer',
          textDecoration: 'underline'
        }}
        sx={{ mt: 2 }}
        variant="body1"
        onClick={drop}
      >
        Start a new subscription
      </Typography>
      )}
    </BasicLayout>
  );
};

export default memo(InfoPanel);
