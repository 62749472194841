import React, { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { Button, Card } from '@mui/material';

import {
  FormField,
  useHookForm,
  useIsProvince,
  FormAutocomplete,
  GlobalInterfaces,
  CountryAutocomplete,
  UsaStatesAutocomplete,
  useCustomGoogleAutocomplete
} from '@monorepo/common';
import { useGetPrimaryBusinessTypesQuery, useGetRolesInCompanyQuery } from '../../../../services/serviceMagazine';
import { IStep2SelectOptionsResponse } from '../../../../services/serviceMagazine/interfaces';
import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { IEventsStepProps, IStep2data } from '../../stepsInterfaces';
import { Step1Values } from '../../../../constants/constants';
import { schema } from './schema';

import { useStyles } from '../../styles';

interface IStep2Props extends IEventsStepProps{
  setStep2: (a: IStep2data) => void,
  setShowStepPayment: (country: boolean) => void,
  step2: IStep2data | null,
  Nav: React.ElementType,
  step1Value: number | null
}

const Step2 = ({ onBack, onNext, setStep2, step2, setShowStepPayment, Nav, step1Value }:IStep2Props) => {
  const classes = useStyles();
  const [changedFields, setChangedFields] = useState<IStep2data | null>(null);
  const [countryCode, setCountryCode] = useState<string | null>(step2?.country || null);
  const [showExtraField, setShowExtraField] = useState<boolean>(false);

  const { data: rolesOptions, isLoading: loadRolesOptions } = useGetRolesInCompanyQuery(null);
  const { data: businessOptions, isLoading: LoadBusinessOptions } = useGetPrimaryBusinessTypesQuery(null);
  const [isStart, setIsStart] = useState<boolean>(false);

  const {
    setValue,
    register,
    getValues,
    handleSubmit,
    errors
  } = useHookForm<IStep2data>({
    schema,
    defaultValues: step2 as IStep2data || null,
    showChangedFields: (a) => {
      setChangedFields(a);
    }
  });

  const { getData, isProvince } = useIsProvince({
    defaultProvince: !((step2?.country === 'US' || step2?.country === 'USA')),
    extraOptions: {
      setValue,
      provinceFieldName: 'province',
      stateFieldName: 'state'
    }
  });

  const { changeInput, renderedList, clearSuggestions } = useCustomGoogleAutocomplete({
    country: countryCode || '',
    mode: 'custom',
    setValue,
    autoFillFields: {
      city: 'city',
      state: 'state',
      zip: 'zipCode',
      province: 'province',
      address1: 'address1'
    }
  });

  const onSubmit = useCallback((data: IStep2data) => {
    if (data && onNext) {
      data.country = countryCode || 'US';
      setStep2(data);
      onNext();
    }
  }, [countryCode, onNext]);

  // if choose country
  const changeCountries = useCallback((val:GlobalInterfaces.IAutocompleteOption | null) => {
    clearSuggestions();
    getData({
      selectOption: val
    });
    setCountryCode(val?.alpha2 || null);
  }, [getData]);

  const goBack = () => {
    if (onBack) {
      if (changedFields && (JSON.stringify(step2).toString() !== JSON.stringify(changedFields))) {
        setStep2(changedFields);
      }
      onBack();
    }
  };

  useEffect(() => {
    setShowStepPayment(step1Value !== Step1Values.DIGITAL_ONLY && (countryCode !== 'US'));
  }, [step1Value, countryCode, setShowStepPayment]);

  // "show | hide" next Payment step
  useEffect(() => {
    setShowExtraField(step2?.primaryBusiness === 'X');
  }, [step2]);

  useEffect(() => {
    setValue('primaryBusiness_Other', showExtraField ? '' : ' ');
  }, [showExtraField]);

  useEffect(() => {
    setValue('isShowState', !isProvince);
  }, [isProvince]);

  useEffect(() => {
    if (rolesOptions && businessOptions) {
      setIsStart(true);
      return;
    }
    setIsStart(false);
  }, [businessOptions, rolesOptions]);

  return (
    <Card className={classes.wrapper_card}>

      <form
        data-cy="step2"
        onSubmit={handleSubmit(onSubmit)}
      >
        {isStart && (
        <CountryAutocomplete
          isRequired
          errors={errors}
          label="Country"
          disableClearable
          setValue={setValue}
          getValues={getValues}
          handleChange={changeCountries}
          extraProps={{ ...register('country') }}
        />
        )}

        <FormField
          isRequired
          errors={errors}
          label="Full Name"
          placeholder="Name"
          extraProps={{ ...register('name') }}
        />
        <FormField
          isRequired
          errors={errors}
          label="Job Title"
          placeholder="Job Title"
          extraProps={{ ...register('title') }}
        />
        <FormAutocomplete<IStep2SelectOptionsResponse>
          optionsSettings={{
            optionValue: 'value',
            optionText: 'text'
          }}
          isRequired
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          options={rolesOptions || []}
          isLoading={loadRolesOptions}
          placeholder="Select value"
          label="Which best describes your role?"
          extraProps={{ ...register('roleInCompany') }}
        />
        <FormField
          isRequired
          label="company"
          errors={errors}
          placeholder="Company"
          extraProps={{ ...register('company') }}
        />
        <FormField
          isRequired
          label="email"
          errors={errors}
          placeholder="Email"
          extraProps={{ ...register('email') }}
        />
        <FormField
          isRequired
          label="phone"
          errors={errors}
          placeholder="Phone #"
          extraProps={{ ...register('phone') }}
        />
        <FormField
          label="fax"
          errors={errors}
          placeholder="Fax"
          extraProps={{ ...register('fax') }}
        />

        <FormField
          isRequired
          errors={errors}
          label="Address"
          placeholder="Address"
          extraProps={{ ...register('address1') }}
          eventInput={(inputValue, event) => {
            changeInput(event as ChangeEvent<HTMLInputElement>);
          }}
        >
          {renderedList}
        </FormField>
        <FormField
          errors={errors}
          label="address2"
          extraProps={{ ...register('address2') }}
          placeholder="Apartment, suite, unit, building, floor, etc."
        />

        <div className={classes.step3_address_wrap}>
          <FormField
            isRequired
            label="City"
            errors={errors}
            placeholder="City"
            extraProps={{ ...register('city') }}
          />
          <FormField
            isRequired
            errors={errors}
            label="Province"
            visible={isProvince}
            placeholder="Province"
            extraProps={{ ...register('province') }}
          />
          {isStart && (
          <UsaStatesAutocomplete
            isRequired
            label="State"
            errors={errors}
            setValue={setValue}
            visible={!isProvince}
            getValues={getValues}
            extraProps={{ ...register('state') }}
          />
          )}
        </div>

        <FormField
          isRequired
          errors={errors}
          label="ZIP/Postal"
          extraProps={{ ...register('zipCode') }}
        />

        <FormAutocomplete<IStep2SelectOptionsResponse>
          optionsSettings={{
            optionText: 'text',
            optionValue: 'value'
          }}
          isRequired
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          label="Which best describes your primary business type?"
          placeholder="Select value"
          options={businessOptions || []}
          isLoading={LoadBusinessOptions}
          extraProps={{ ...register('primaryBusiness') }}
          onChange={(val) => {
            if (!Array.isArray(val)) {
              setShowExtraField(val?.value === 'X');
            }
          }}
        />
        <FormField
          visible={showExtraField}
          isRequired
          errors={errors}
          label="Other business type:"
          extraProps={{ ...register('primaryBusiness_Other') }}
        />

        <Nav
          nextDisabled={false}
          onBack={goBack}
          nextButton={(
            <Button
              type="submit"
              variant="contained"
              endIcon={(<ArrowRightIcon />)}
            >
              Next Step
            </Button>
          )}
        />
      </form>

    </Card>
  );
};

export default memo(Step2);
