import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { baseUrls, cookieNames, GlobalInterfaces } from '@monorepo/common';
import cookies from 'js-cookie';
import { showNotification } from '../features/sliceNotification';

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrls.jobApi,
  prepareHeaders: (headers) => {
    headers.set('Authorization', `Bearer ${cookies.get(cookieNames.bevnet_token)}`);
    return headers;
  }
});

interface IOldErrorModel {
  data?: {
    error?: string
  },
  status?: number
}
const errorStatus: number[] = [400, 401, 403, 404];

export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    if (!errorStatus.includes(result.error.status as number)) {
      if ('data' in result.error) {
        const err = result.error as IOldErrorModel;
        if (err.data?.error) {
          api.dispatch(showNotification({
            type: 'error',
            show: true,
            text: err.data?.error
          }));
        }
      }
    }

    if (result.error && ('originalStatus' in result.error)) {
      const err = result.error as GlobalInterfaces.IErrorQueryProps;
      if (err?.originalStatus === 500) {
        api.dispatch(showNotification({
          type: 'error',
          show: true,
          text: 'Internal server Error!'
        }));
      }
      if (err?.originalStatus && errorStatus.includes(err.originalStatus)) {
        api.dispatch(showNotification({
          type: 'error',
          show: true,
          text: err.data
        }));
      } else {
        console.error(result.error);
        api.dispatch(showNotification({
          type: 'error',
          show: true,
          text: 'Something went wrong'
        }));
      }
    }

    if (result.error.status === 'PARSING_ERROR') {
      api.dispatch(showNotification({
        type: 'error',
        show: true,
        text: result.error.data
      }));
      return result;
    }

    if (result.error.status > 201) {
      const { errors } = result.error.data as { errors?: { [key: string]: string[] } };
      if (errors) {
        let errStr = '';
        Object.keys(errors)
          .forEach((item) => {
            errStr += `${item}: ${errors[item]} \n`;
          });
        api.dispatch(showNotification({
          type: 'error',
          show: true,
          text: errStr
        }));
        return result;
      }
      return result;
    }
  }
  return result;
};
