import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { book, useStepper, BasicLayout } from '@monorepo/common';

import Step1 from './steps/Steps1';
import Step2 from './steps/Steps2';
import Step3 from './steps/Steps3';

import { IRenewStep2Response, IStep1Response } from '../../services/serviceMagazine/interfaces';
import { toggleSkipStartPage } from '../../features/sliceTemporaryData';
import { useGetRenewDataQuery, useGetSubscriptionTypesQuery } from '../../services/serviceMagazine';
import StartMagazine from './components/StartMagazine';
import { useAppSelector } from '../../store/hooks';
import InfoPanel from './components/InfoPanel';

export const Renew = () : JSX.Element => {
  const dispatch = useDispatch();
  const { id, slug } = useParams<{ id: string, slug: string }>();

  const { isLoading, data, error } = useGetRenewDataQuery({ id }, {
    skip: !id
  });

  const { isLoading: loadingSubscriptionTypes, data: subscriptionTypesData } = useGetSubscriptionTypesQuery(null);

  const { skipStartPage } = useAppSelector(({ temporaryData }) => ({
    skipStartPage: temporaryData.skipStartPage
  }));

  const [showStepper, setShowStepper] = useState<boolean>(false);

  const [step1, setStep1] = useState<IStep1Response | null>(null);
  const [step2, setStep2] = useState<IRenewStep2Response | null>(null);
  const [step3, setStep3] = useState<boolean>(false);

  useEffect(() => {
    if (skipStartPage) {
      setShowStepper(true);
      dispatch(toggleSkipStartPage(false));
    }
  }, [skipStartPage]);

  useEffect(() => {
    if (data && subscriptionTypesData) {
      const res:IStep1Response = subscriptionTypesData.find((item) => item.value === data.subscriptionType);
      setStep1(res || null);
      setStep2({
        ...data,
        ...(data.country === 'USA') && { country: 'US' }
      });
    }
  }, [data, subscriptionTypesData]);

  const { putSteps, onNext, onBack, onComplete, renderStepper, StepsButtons, resetActiveStep } = useStepper({
    isLoading,
    helmet: 'BevNET Magazine: Subscription Renewal',
    backStepSettings: {
      text: 'Back to the start page',
      handlerClick: () => {
        setShowStepper(false);
        resetActiveStep();
      }
    },
    none_data: !!error,
    leftInfoPanel: (<InfoPanel />)
  });

  useEffect(() => {
    if (slug) {
      setShowStepper(true);
    }
  }, [slug]);

  useEffect(() => {
    if (data) {
      putSteps([
        {
          label: 'Subscription Type',
          content: (
            <Step1
              country={data?.country || 'USA'}
              Nav={StepsButtons}
              setStep1={setStep1}
              onNext={onNext}
              defaultData={step1}
            />
          )
        },
        {
          label: 'Subscription Details',
          content: (
            <Step2
              Nav={StepsButtons}
              step2={step2}
              setStep2={setStep2}
              onBack={onBack}
              onNext={onNext}
            />
          )
        },
        {
          label: 'Confirmation',
          content: (
            <Step3
              Nav={StepsButtons}
              step3={step3}
              step2data={step2}
              step1Data={step1}
              setStep3={setStep3}
              onBack={onBack}
              onNext={onComplete}
            />
          )
        }
      ]);
    }
  }, [step1, step2, step3, data, subscriptionTypesData]);

  useEffect(() => {
    if (error || !id) {
      window.location.href = book.magazine.subscribe;
    }
  }, [error, id]);

  return (
    <BasicLayout
      helmet="BevNET Magazine: Subscription Renewal"
      isLoading={isLoading || loadingSubscriptionTypes}
    >
      <div data-cy="renew_page">
        {showStepper ? renderStepper : (
          <StartMagazine
            data={step2}
            toggleStepper={setShowStepper}
          />
        )}
      </div>
    </BasicLayout>
  );
};
