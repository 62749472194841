import * as yup from 'yup';

export const schema:any = yup.object().shape({
  isShowBillingState: yup.boolean(),
  billingFirstName: yup.string().required('Required').nullable(),
  billingLastName: yup.string().required('Required').nullable(),
  billingCompany: yup.string().required('Required').nullable(),
  billingEmail: yup.string().email('The email  field is not valid.').required('Required').nullable(),
  billingPhone: yup.string().required('Required').nullable(),

  billingCountry: yup.string().required('Required').nullable(),
  billingAddress1: yup.string().required('Required').nullable(),
  billingAddress2: yup.string().nullable(),
  billingCity: yup.string().required('Required').nullable(),
  billingState: yup.string().nullable()
    .when('isShowBillingState', {
      is: true,
      then: yup.string().required('Required').nullable(),
      otherwise: yup.string().nullable()
    }),
  billingProvince: yup.string().nullable(),
  billingZipcode: yup.string().required('Required').nullable(),

  ccNumber: yup.string().min(12, 'Min length 12 numbers').required('Required'),
  ccExpMonth: yup.string().required('Required'),
  ccExpYear: yup.string().required('Required'),
  cccvv: yup.string().min(3, 'Min length 3 numbers').required('Required'),
});
