import React, { memo } from 'react';
import { Typography } from '@mui/material';

const InfoPanel = () => (
  <>
    <Typography
      sx={{ mb: 3 }}
      variant="h4"
      fontWeight="bold"
    >
      Renew Your FREE BevNET Magazine Subscription
    </Typography>

    <p>
      By renewing your BevNET Magazine subscription, you will receive 6 additional issues over the next year. Get access to:
    </p>
    <ul>
      <li>Beverage, food and beer industry insights, analysis, and forecasting</li>
      <li>Timely industry data on ALL of the key categories</li>
      <li>New product coverage of the beverage, beer, spirits and natural food categories</li>
    </ul>
    <p>Please review the information below to be sure you receive the next issues of the BevNET Magazine.</p>
  </>
);

export default memo(InfoPanel);
