import React, { useEffect, useRef, useState } from 'react';
import { Button, Typography } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { book, Loader } from '@monorepo/common';

import { IRenewStep2Response, IStep1Response } from '../../../services/serviceMagazine/interfaces';
import { showNotification } from '../../../features/sliceNotification';
import { useGetSubscriptionTypesQuery, usePutRenewDataMutation } from '../../../services/serviceMagazine';
import { SETTINGS } from '../../../constants/constants';

import { displayTypePage } from '../../../features/sliceTemporaryData';
import { useStyles } from '../styles';
import AddressInfo from '../../../components/AddressInfo/AddressInfo';

interface IStartMagazineProps {
  data: IRenewStep2Response | null,
  toggleStepper: (status: boolean) => void
}

const StartMagazine = (data:IStartMagazineProps) : JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [subscribeType, setSubscribeType] = useState<IStep1Response | null>(null);

  const [putRenewData, { isSuccess, isLoading, error }] = usePutRenewDataMutation();
  const { isLoading: loadingTypes, data: typesData } = useGetSubscriptionTypesQuery(null);

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { data: userData, toggleStepper } = data;

  const saveData = async () => {
    if (recaptchaRef && recaptchaRef.current) {
      const captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();

      if (userData && captchaToken) {
        if (!userData.roleInCompany) {
          userData.roleInCompany = 'X';
        }
        await putRenewData({
          ...userData,
          country: userData.country === 'US' ? 'USA' : userData.country,
          captchaToken
        });
        return;
      }
    }
    dispatch(showNotification({
      show: true,
      type: 'error',
      text: 'Captcha token not found'
    }));
  };

  useEffect(() => {
    if (isSuccess && !error) {
      dispatch(displayTypePage('renew'));
      history.push(book.magazine.subscriptionComplete);
    }

    if (error && ('data' in error)) {
      const { message } = error.data as { message: string };
      dispatch(showNotification({
        show: true,
        type: 'error',
        text: message
      }));
    }
  }, [isSuccess, history, error]);
  useEffect(() => {
    if (userData && typesData) {
      const res:IStep1Response | undefined = typesData.find((item) => item.value === userData.subscriptionType);
      setSubscribeType(res || null);
    }
  }, [typesData, userData]);

  if (userData) {
    return (
      <div
        className={classes.start_magazine_page}
        data-cy="start_magazine_page"
      >
        <Loader isShow={isLoading || loadingTypes} />
        <ReCAPTCHA
          style={{
            visibility: 'hidden'
          }}
          size="invisible"
          ref={recaptchaRef}
          sitekey={SETTINGS.RECAPTCHA_PUBLIC_KEY}
        />
        <Typography
          sx={{ mb: 3 }}
          variant="h4"
          fontWeight="bold"
        >
          Subscription Renewal  for BevNET Magazine
        </Typography>

        <AddressInfo
          data={userData}
          type={subscribeType}
        />

        <div className={classes.line}>
          <Button
            variant="outlined"
            data-cy="cy_edit_subscribe"
            onClick={() => toggleStepper(true)}
          >
            EDIT SUBSCRIPTION DETAILS
          </Button>

          <Button
            variant="contained"
            onClick={saveData}
            data-cy="cy_to_renew"
          >
            Yes, I want to renew
          </Button>
        </div>

        <Typography
          sx={{ mt: 3 }}
          variant="body1"
          lineHeight={2}
        >
          Don’t want to receive BevNET Magazine anymore?
          {' '}
          <Link
            to={book.magazine.cancel(userData?.guid as string)}
            className={classes.link}
            data-cy="link_to_cancel_page"
          >
            Click here to cancel
          </Link>
        </Typography>

      </div>
    );
  }
  return null;
};

export default StartMagazine;
